import React, { useContext } from "react";
import { Box, Typography, Link, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import { IntlContext } from "src/App";
import { createStyles, makeStyles } from "@mui/styles";
import moment from "moment";
import { Config } from "src/helpers/context/config";
import { useSelector } from "react-redux";
import { fetchFromStorage } from "src/helpers/context/Storage";
import invoice from "src/assets/images/icons/file-invoice-dollar.svg";
import calendarDay from "src/assets/images/icons/calendar-day.svg";
import calendarCheck from "src/assets/images/icons/calendar-check.svg";
import dropdown_Arrow from "src/assets/images/icons/down_arrow.svg";
import up_down_arrow from "src/assets/images/icons/up_down_arrow.svg";
// import DialogBox from "../UI/DialogBox";
// import LogIn from "../../Auth/Login/Login";
// import SignUp from "../../Auth/Signup/Signup";
import axiosInstance from "src/helpers/context/axiosInstance";
import { URLS } from "src/helpers/constants/url";

const useStyles = makeStyles((theme) =>
  createStyles({
    root_d: {
      width: "100%",
      "@media (max-width:1024px)": {
        // width: "888px",
        display: "none",
      },
      "& .summery_block": {
        backgroundColor: "#F1F9FF",
        borderRadius: "20px",
        padding: "27px 10px 32px",
        textAlign: "center",
        "@media (max-width:899px)": {
          padding: "25px 10px",
        },
        "@media (max-width:599px)": {
          padding: "20px 10px ",
        },
        "& .MuiTableCell-root": {
          textAlign: "center",
          borderBottom: "none",
          padding: "14px",
          "@media (max-width:1199px)": {
            padding: "14px",
          },
        },
        "& .title": {
          fontWeight: "600",
          lineHeight: "20px",
          color: "#373737",
          textTransform: "uppercase",
          // paddingTop: "12px",
          // "@media (max-width:1199px)": {
          //   fontSize: "18px",
          //   lineHeight: "18px",
          // },
          "@media (max-width:899px)": {
            fontSize: "14px",
            lineHeight: "16px",
            // paddingTop: "10px",
          },
          "@media (max-width:599px)": {
            fontSize: "12px",
            lineHeight: "14px",
          },
        },
        "& .test_index": {
          fontWeight: "500",
        },
        "& .test_name": {
          // fontSize: "18px",
          fontSize: "22px",
          fontWeight: "600",
          // lineHeight: "26px",
          "@media (max-width:1199px)": {
            fontSize: "20px",
            lineHeight: "24px",
          },
          "@media (max-width:899px)": {
            fontSize: "18px",
            lineHeight: "22px",
          },
          "@media (max-width:599px)": {
            fontSize: "16px",
            lineHeight: "20px",
          },
        },
        "& .test_detail": {
          fontSize: "18px",
          lineHeight: "20px",
          fontWeight: "600",
          color: "#373737",
          padding: "4px 0",
          "@media (max-width:899px)": {
            fontSize: "16px",
            lineHeight: "18px",
          },
          "@media (max-width:599px)": {
            fontSize: "14px",
            lineHeight: "16px",
          },
        },
        "& .test_timedate": {
          fontSize: "14px",
          lineHeight: "20px",
          color: "#373737",
          "@media (max-width:899px)": {
            fontSize: "12px",
            lineHeight: "18px",
          },
        },
        "& .dwld_invoice": {
          textDecoration: "underline",
          color: "#405CFB",
        },
        "& svg": {
          "@media (max-width:899px)": {
            width: "30px",
          },
        },
        "& .view_test": {
          fontWeight: "500",
          display: "block",
        },
      },
      "& .summery_Cblock": {
        backgroundColor: "#FAEDFF",
      },
      "& .summery_Tblock": {
        backgroundColor: "#EFEFEF",
      },
    },
    root_m: {
      display: "none",
      "@media (max-width:1024px)": {
        width: "100%",
        display: "block",
      },
      "& .mSummery_block": {
        backgroundColor: "#F6F6F6",
        borderRadius: "3px",
        padding: "18px 20px",
        textAlign: "center",
        "& .toggle_arrow": {
          transform: "rotate(90deg)",
          "@media (max-width:899px)": {
            width: "9px",
            height: "12px",
          },
          "@media (max-width:599px)": {
            width: "7px",
            height: "10px",
          },
        },
        "@media (max-width:899px)": {
          padding: "16px 18px",
        },
        "@media (max-width:599px)": {
          padding: "11px 12px",
        },
      },
      "& .test_name": {
        fontSize: "18px",
        lineHeight: "21px",
        fontWeight: "600",
        color: "#41A6E9",
        "@media (max-width:899px)": {
          fontSize: "16px",
          lineHeight: "19px",
        },
        "@media (max-width:599px)": {
          fontSize: "14px",
          lineHeight: "17px",
        },
      },
      "& .title": {
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "16px",
        color: "#41A6E9",
        textTransform: "uppercase",
        "@media (max-width:899px)": {
          fontSize: "12px",
          lineHeight: "14px",
        },
        "@media (max-width:599px)": {
          fontSize: "9px",
          lineHeight: "11px",
        },
      },
    },
  })
);

const Dashboard_summery = ({
  product,
  dashboardData,
  id,
  myOrderId,
  handleAcChange,
  idData,
  index,
  isloader,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const redux_auth = useSelector((state) => state?.ActionReduce?.authdata);
  const auth = redux_auth ? redux_auth : fetchFromStorage("authdata");
  const sub_date = moment(dashboardData?.orderDate).format("DD-MM-YYYY");
  const toDay = moment(new Date());
  const remain_days =
    364 - toDay.diff(moment(dashboardData?.orderDate), "days");

  const getCartItem = async (val) => {
    let payload = {
      orderId: val,
      productForm: [],
      user: auth?.data?._id,
    };
    isloader(true);
    try {
      const { data, status } = await axiosInstance.post(
        URLS.getCartItem,
        payload
      );
      if (status === 200) {
        isloader(false);
        addCartItemData(data?.data, product);
      } else {
        isloader(false);
      }
    } catch (error) {
      isloader(false);
    }
  };

  const addCartItemData = async (data, product) => {
    isloader(true);

    let filterData = data.productForm?.filter((item) => {
      return item?._id === product?._id;
    });

    let prodData =
      filterData?.length > 0
        ? [...data.productForm]
        : [...data.productForm, product];

    let payload = {
      createdAt: data?.createdAt,
      orderId: data?.orderId,
      updatedAt: data?.updatedAt,
      user: data?.user,
      _id: data?._id,
      productForm: prodData?.map((item) => {
        return {
          ...item,
          orderDate: moment().format("YYYY-MM-DD  HH:mm:ss ZZ"),
        };
      }),
      __v: data?.__v,
    };

    try {
      const { status } = await axiosInstance.post(URLS.addCartItem, payload);
      if (status === 200) {
        isloader(false);
        navigate("/cart");
      } else {
        isloader(false);
      }
    } catch (error) {
      isloader(false);
    }
  };

  return (
    <>
      {/* DESKTOP VIEW */}

      <Box className={classes.root_d}>
        <Box
          className={`summery_block ${
            product?.categoryLevel3 ? "summery_Cblock" : ""
          } ${product?.productForm ? "summery_Tblock" : ""} `}
        >
          <Grid container spacing={2} sx={{ alignItems: "center" }}>
            <Grid item size={{ xs: 0.5 }}>
              <Typography className="title test_index">{id}</Typography>
            </Grid>
            <Grid item size={{ xs: 1 }} sx={{ textAlign: "left" }}>
              <Typography className="title">
                {product?.categoryLevel2 && gothe_mile?.dashboard?.omnibus}
                {product?.categoryLevel3 && gothe_mile?.dashboard?.chapter}
                {product?.productForm && gothe_mile?.test}
              </Typography>
            </Grid>
            <Grid item size={{ xs: 3.4 }} sx={{ textAlign: "left" }}>
              <Typography className="test_name">
                {product?.categoryLevel2 && product?.categoryLevel2?.name}
                {product?.categoryLevel3 && product?.categoryLevel3?.name}
                {product?.productForm && product?.productForm?.name}
              </Typography>
            </Grid>
            {product?.categoryLevel2 && (
              <>
                <Grid item size={{ xs: 1.9 }}>
                  <Box>
                    <img src={invoice} alt="Invoice" />
                    <Typography className="test_detail">
                      {"$" + product?.categoryLevel2?.price}
                    </Typography>
                    <Link
                      target="_blank"
                      href={`${Config.baseURL}/downloadWithName/${dashboardData?.invoiceUrl}`}
                      className="test_timedate dwld_invoice"
                    >
                      {gothe_mile?.dashboard?.download_invoice}
                    </Link>
                  </Box>
                </Grid>
                <Grid item size={{ xs: 1.9 }}>
                  <Box>
                    <img src={calendarCheck} alt="Calander Icon" />
                    <Typography className="test_detail">{sub_date}</Typography>
                    <Typography className="test_timedate">
                      {gothe_mile?.dashboard?.subscription_date}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item size={{ xs: 1.9 }}>
                  <Box>
                    {remain_days > 0 ? (
                      <>
                        <img src={calendarDay} alt="Calander Day Icon" />
                        <Typography className="test_detail">
                          {remain_days} {gothe_mile?.days}
                        </Typography>
                        <Typography className="test_timedate">
                          {gothe_mile?.dashboard?.time_remaining}
                        </Typography>
                      </>
                    ) : (
                      <>
                        {/* <Typography className="test_detail">
                          subscription
                        </Typography>
                        <Typography className="test_detail">expired</Typography> */}
                        <Button
                          variant="blue"
                          className="tRtest_btn"
                          onClick={() => getCartItem(myOrderId, product)}
                        >
                          {gothe_mile?.dashboard?.resubscribe}
                        </Button>
                      </>
                    )}
                  </Box>
                </Grid>
              </>
            )}

            {product?.categoryLevel3 || product?.productForm ? (
              <>
                <Grid item size={{ xs: 5.7 }}>
                  <Grid container spacing={2}>
                    <Grid item size={{ xs: 4 }}>
                      <Box>
                        <img src={invoice} alt="Invoice" />
                        <Typography className="test_detail">
                          {product?.categoryLevel3 &&
                            "$" + product?.categoryLevel3?.price}
                          {product?.productForm &&
                            "$" + product?.productForm?.price}
                        </Typography>
                        <Link
                          target="_blank"
                          href={`${Config.baseURL}/downloadWithName/${dashboardData?.invoiceUrl}`}
                          className="test_timedate dwld_invoice"
                        >
                          {gothe_mile?.dashboard?.download_invoice}
                        </Link>
                      </Box>
                    </Grid>
                    <Grid item size={{ xs: 4 }}>
                      <Box>
                        <img src={calendarCheck} alt="Calander Icon" />
                        <Typography className="test_detail">
                          {sub_date}
                        </Typography>
                        <Typography className="test_timedate">
                          {gothe_mile?.dashboard?.subscription_date}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item size={{ xs: 4 }}>
                      <Box>
                        {remain_days > 0 ? (
                          <>
                            <img src={calendarDay} alt="Calander Day Icon" />
                            <Typography className="test_detail">
                              {remain_days} {gothe_mile?.days}
                            </Typography>
                            <Typography className="test_timedate">
                              {gothe_mile?.dashboard?.time_remaining}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Button
                              variant="blue"
                              className="tRtest_btn"
                              onClick={() => getCartItem(myOrderId, product)}
                            >
                              {gothe_mile?.dashboard?.resubscribe}
                            </Button>
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: "6px" }}>
                    <Grid item size={{ xs: 4 }}>
                      <Typography
                        className="test_detail"
                        sx={{ p: "4px 0 0!important" }}
                      >
                        {gothe_mile?.score1}
                      </Typography>
                    </Grid>
                    <Grid item size={{ xs: 4 }}>
                      <Typography
                        className="test_detail"
                        sx={{ p: "4px 0 0!important" }}
                      >
                        {gothe_mile?.score2}
                      </Typography>
                    </Grid>
                    <Grid item size={{ xs: 4 }}>
                      <Typography
                        className="test_detail"
                        sx={{ p: "4px 0 0!important" }}
                      >
                        {gothe_mile?.score3}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )}

            <Grid item size={{ xs: 1.4 }}>
              <Box
                onClick={() => {
                  handleAcChange(idData, index);
                }}
              >
                <img
                  src={dropdown_Arrow}
                  alt="arrow"
                  className="toggle_arrow"
                />
                <Typography className="test_timedate view_test">
                  {gothe_mile?.view_test}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* MOBILE VIEW */}
      <Box
        className={classes.root_m}
        onClick={() => {
          handleAcChange(idData, index);
        }}
      >
        <Box
          className={`mSummery_block ${
            product?.categoryLevel3 ? "summery_Cblock" : ""
          } ${product?.productForm ? "summery_Tblock" : ""} `}
        >
          <Grid container spacing={2} style={{ alignItems: "center" }}>
            <Grid item size={{ xs: 0.4 }}>
              <Typography className="title test_index">{id}</Typography>
            </Grid>
            <Grid item size={{ xs: 2.2, sm: 1.8, md: 1.6 }}>
              <Typography className="title">
                {product?.categoryLevel2 && gothe_mile?.dashboard?.omnibus}
                {product?.categoryLevel3 && gothe_mile?.dashboard?.chapter}
                {product?.productForm && gothe_mile?.test}
              </Typography>
            </Grid>
            <Grid
              item
              size={{ xs: 7.8, sm: 9, md: 9.4 }}
              style={{ textAlign: "left" }}
            >
              <Typography className="test_name">
                {product?.categoryLevel2 && product?.categoryLevel2?.name}
                {product?.categoryLevel3 && product?.categoryLevel3?.name}
                {product?.productForm && product?.productForm?.name}
              </Typography>
            </Grid>
            <Grid item size={{ xs: 1, sm: 0.6, md: 0.5 }}>
              <Box>
                <img src={up_down_arrow} alt="arrow" className="toggle_arrow" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default Dashboard_summery;
