import React, { useContext, useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  Link,
  Typography,
  ClickAwayListener,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import InputAdornment from "@mui/material/InputAdornment";
import {
  removeFromStorage,
  fetchFromStorage,
  saveToStorage,
} from "src/helpers/context/Storage";
import { Formik } from "formik";
import * as Yup from "yup";
import NavMenu from "./NavMenu";
import "./topbar.scss";
import { IntlContext } from "src/App";
import { InputSearchField } from "src/view/component/UI/textfield";
import AppLogo from "src/component/Logo";
import { ReactComponent as Search } from "src/assets/images/icons/search.svg";
import CartIcon from "src/assets/images/icons/cart-blue.svg";
import { ReactComponent as MenuIcon } from "src/assets/images/icons/menu-icon.svg";
import dropdown_Arrow from "src/assets/images/icons/down_arrow.svg";
import up_Arrow from "src/assets/images/icons/up_arrow.svg";
import whatsapp from "src/assets/images/icons/WhatsApp.svg";
import DialogBox from "src/view/component/UI/DialogBox";
import LogIn from "src/view/Auth/Login/Login";
import SignUp from "src/view/Auth/Signup/Signup";
import { URLS } from "src/helpers/constants/url";
import axiosInstance from "src/helpers/context/axiosInstance";
import { identifiers } from "src/helpers/constants/identifier";
import MessageBox from "src/view/component/UI/MessageBox";
import PreLoader from "src/component/Loader";
import { HashLink } from "react-router-hash-link";
import { loginUser } from "src/helpers/store/Actions/auth";
import { searchText } from "src/helpers/store/Actions/search";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const redux_auth = useSelector((state) => state?.ActionReducer?.authdata);
  const auth = redux_auth ? redux_auth : fetchFromStorage("authdata");
  const [togglePopup, setTogglePopup] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [popup, setPopup] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [logoutPopup, setLogoutPopup] = useState(false);
  const [loader, setLoader] = useState(false);

  const OpenPopup = () => {
    setPopup(!popup);
    setTimeout(() => {
      setTogglePopup(true);
    }, 200);
  };

  const goToCart = () => {
    navigate("/cart");
    !auth && setPopup(!popup);
  };

  const openLogoutPopup = () => {
    setLogoutPopup(true);
    setToggle(false);
  };

  const Logout = () => {
    setLogoutPopup(false);
    window.location = "/";
    removeFromStorage("authdata");
    removeFromStorage(identifiers?.currentLocation);
  };

  const closelogoutPopup = () => {
    setLogoutPopup(false);
  };

  const setUser = async () => {
    let payload = {
      accessLevel: auth?.data?.accessLevel,
      email: auth?.data?.email,
      name: auth?.data?.name,
      firstName: auth?.data?.name,
      lastName: auth?.data?.name,
      mobile: auth?.data?.mobile,
      photo: auth?.data?.photo,
      _id: auth?.data?._id,
    };
    setLoader(true);
    try {
      const { data, status } = await axiosInstance.post(URLS.setUser, payload);
      if (status === 200) {
        if (data?.value === true) {
          setLoader(false);
          saveToStorage("authdata", data);
          dispatch(loginUser(data));
        } else {
          setLoader(false);
        }
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    // getCategoryName();
    auth && setUser();
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  const listenToScroll = () => {
    let heightToHideFrom = 550;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll < heightToHideFrom) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  return (
    <>
      {loader && <PreLoader />}
      {togglePopup ? (
        <DialogBox
          open={popup}
          handleClose={OpenPopup}
          title={gothe_mile?.logIn}
          content={
            <LogIn
              togglePopup={togglePopup}
              setTogglePopup={setTogglePopup}
              setPopup={setPopup}
            />
          }
        />
      ) : (
        <DialogBox
          open={popup}
          handleClose={OpenPopup}
          title={gothe_mile?.signUp}
          content={
            <SignUp
              setTogglePopup={setTogglePopup}
              togglePopup={togglePopup}
              setPopup={setPopup}
            />
          }
        />
      )}
      <MessageBox
        open={logoutPopup}
        handleClose={closelogoutPopup}
        content={
          <>
            <Typography
              variant="body1"
              sx={{ mb: { lg: "45px", md: "35px", sm: "30px", xs: "25px" } }}
            >
              {gothe_mile?.message?.sureLogout}
            </Typography>
            <Grid container spacing={3} sx={{ justifyContent: "center" }}>
              <Grid item size={{ xs: 4 }}>
                <Button
                  variant="browse_blue"
                  className="logout_btn"
                  onClick={Logout}
                >
                  {gothe_mile?.yes}
                </Button>
              </Grid>
              <Grid item size={{ xs: 4 }}>
                <Button
                  variant="browse_blue"
                  className="logout_btn"
                  onClick={closelogoutPopup}
                >
                  {gothe_mile?.no}
                </Button>
              </Grid>
            </Grid>
          </>
        }
      />
      <Box className="header_section">
        <Grid container className="flex_content">
          <Grid item size={{ xs: 4, lg: 1 }} style={{ display: "flex" }}>
            <NavLink to="/">
              <AppLogo />
            </NavLink>
          </Grid>
          <Grid item size={{ lg: 7 }} className="hide-1279">
            {/* <Hidden mdDown> */}
            <Box className="menu_wrap">
              {identifiers?.nav_menu?.map((item, id) => {
                return (
                  <>
                    {item?.label === "Blog" ? (
                      <Link
                        key={id}
                        target="_blank"
                        className={
                          location?.pathname === item?.href
                            ? "menu_link active"
                            : "menu_link"
                        }
                        href={item?.href}
                      >
                        {item?.label}
                      </Link>
                    ) : (
                      <HashLink
                        key={id}
                        className={
                          location?.pathname === item?.href
                            ? "menu_link active"
                            : "menu_link"
                        }
                        smooth
                        to={item?.href}
                      >
                        {item?.label}
                      </HashLink>
                    )}
                  </>
                );
              })}
            </Box>
          </Grid>
          <Grid item size={{ xs: 4, lg: 4 }}>
            <Box className="button-wrap">
              <Box className="icon_wrap show-1279">
                <Box className="menu_icon">
                  <MenuIcon onClick={() => setOpenMenu(true)} />
                </Box>
                <Box className="search_icon hide-899">
                  <Formik
                    initialValues={{ search: "" }}
                    validationSchema={Yup.object().shape({
                      search: Yup.string().required("Please Enter Text"),
                    })}
                    onSubmit={async (data) => {
                      navigate(`/search/${data?.search}`);
                      saveToStorage("searchText", data?.search);
                      dispatch(searchText(data?.search));
                    }}
                  >
                    {({ handleBlur, handleChange, handleSubmit, values }) => (
                      <form onSubmit={handleSubmit}>
                        <InputSearchField
                          placeholder="Search"
                          variant="outlined"
                          type="text"
                          name="search"
                          className="search_input"
                          value={values?.search}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Search />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </form>
                    )}
                  </Formik>
                </Box>
                <Box className="cart_icon">
                  {/* <MobileCartIcon /> */}
                  <img
                    src={CartIcon}
                    style={{ width: "26px" }}
                    alt="desktop-cart"
                    onClick={goToCart}
                  />
                </Box>
              </Box>
              <NavMenu
                openMobile={openMenu}
                nav_menu={identifiers?.nav_menu}
                sub_menu={identifiers?.sub_menu}
                onMobileClose={() => setOpenMenu(false)}
                openLogoutPopup={openLogoutPopup}
                OpenPopup={OpenPopup}
              />
              <Box
                className="hide-1279"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Formik
                  initialValues={{ search: "" }}
                  validationSchema={Yup.object().shape({
                    search: Yup.string().required("Please Enter Text"),
                  })}
                  onSubmit={async (data) => {
                    navigate(`/search/${data?.search}`);
                    saveToStorage("searchText", data?.search);
                    dispatch(searchText(data?.search));
                  }}
                >
                  {({ handleBlur, handleChange, handleSubmit, values }) => (
                    <form onSubmit={handleSubmit}>
                      <InputSearchField
                        placeholder="Search"
                        variant="outlined"
                        type="text"
                        name="search"
                        className="search_input"
                        value={values?.search}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </form>
                  )}
                </Formik>
                <Button
                  className="cart-btn"
                  disableElevation
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                >
                  <img src={CartIcon} alt="desktop-cart" onClick={goToCart} />
                </Button>
                {!auth ? (
                  <Button variant="blue" onClick={OpenPopup}>
                    {gothe_mile?.logIn} / {gothe_mile?.signUp}
                  </Button>
                ) : (
                  <Box className="user_block">
                    <Typography className="user_name">
                      {gothe_mile?.greeting}
                      <NavLink
                        to="/dashboard"
                        style={{ color: "#4AB5FC", wordBreak: "break-word" }}
                      >
                        {auth?.data?.name}
                      </NavLink>
                    </Typography>
                    {!toggle ? (
                      <img
                        src={dropdown_Arrow}
                        alt="arrow"
                        style={{ cursor: "pointer" }}
                        onClick={() => setToggle(true)}
                      />
                    ) : (
                      <img
                        src={up_Arrow}
                        alt="arrow"
                        style={{ cursor: "pointer" }}
                        onClick={() => setToggle(false)}
                      />
                    )}
                    {toggle && (
                      <ClickAwayListener onClickAway={() => setToggle(false)}>
                        <Box className="sub_menu_wraper">
                          {identifiers?.sub_menu?.map((item, id) => {
                            return (
                              <>
                                <Box key={id} className="sub_menu_block">
                                  <img src={item?.icon} alt="Icon" />
                                  <NavLink
                                    to={item?.href}
                                    className="sun_menu_name"
                                    onClick={
                                      item?.name == "Log out" && openLogoutPopup
                                    }
                                  >
                                    {item?.name}
                                  </NavLink>
                                </Box>
                              </>
                            );
                          })}
                        </Box>
                      </ClickAwayListener>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box className="search_icon show-899">
          <Formik
            initialValues={{ search: "" }}
            onSubmit={async (data) => {
              navigate(`/search/${data?.search}`);
              saveToStorage("searchText", data?.search);
              dispatch(searchText(data?.search));
            }}
          >
            {({ handleBlur, handleChange, handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <InputSearchField
                  placeholder="Search"
                  variant="outlined"
                  type="text"
                  name="search"
                  className="search_input"
                  value={values?.search}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  sx={{ ml: "10px" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            )}
          </Formik>
        </Box>
      </Box>
      {isVisible && (
        <>
          <a
            href="https://api.whatsapp.com/send?phone=61402090219"
            target="_blank"
          >
            <Box className="whatsapp_block">
              <img src={whatsapp} alt="WhatsApp Icon" />
              <Typography className="whatsapp_text">
                {gothe_mile?.WhatsApp_us}
              </Typography>
            </Box>
          </a>
        </>
      )}
    </>
  );
};
export default Header;
