import test from "../../assets/images/icons/test.svg";
import setting from "../../assets/images/icons/setting.svg";
import logoutIcon from "../../assets/images/icons/logout.svg";
import { ReactComponent as Stripe } from "../../assets/images/icons/Stripe.svg";
import { ReactComponent as AmericanExpress } from "../../assets/images/icons/American-Express.svg";
import { ReactComponent as Mastercard } from "../../assets/images/icons/mastercard.svg";
import { ReactComponent as Visa } from "../../assets/images/icons/visa.svg";
import { ReactComponent as Paypal } from "../../assets/images/icons/Paypal.svg";
import { ReactComponent as Facebook } from "../../assets/images/icons/Facebook.svg";
import { ReactComponent as LinkedIn } from "../../assets/images/icons/LinkedIn.svg";
import { ReactComponent as Twitter } from "../../assets/images/icons/Twitter.svg";
import { Config } from "../context/config";

export const identifiers = {
  token: "authData",
  active: "active",
  dropDown: "Drop down",
  visitorUserId: "visitorUserId",
  authData: "authData",
  currentLocation: "current_location",
  nav_menu: [
    { label: "Home", href: "/" },
    { label: "Sample Tests", href: "/testfree" },
    {
      label: "Subscription Options",
      href: "/#subscription",
      sectionId: "subscription",
      value: "Subscription Options",
      // categoryList: [],
    },
    { label: "About us", href: "/#aboutus", sectionId: "aboutus" },
    {
      label: "Testimonials",
      href: "/#testimonials",
      sectionId: "testimonials",
    },
    { label: "Contact", href: "/#contact", sectionId: "contact" },
    { label: "Blog", href: `${Config?.blogURL}` },
  ],
  sub_menu: [
    { icon: test, name: "My Tests", href: "/dashboard" },
    { icon: setting, name: "My Settings", href: "/userprofile" },
    { icon: logoutIcon, name: "Log out", href: "#" },
  ],
  table_header: [
    { header: "Sr.No", width: "9%", xswidth: "5%" },
    { header: "Particulars", width: "60%", xswidth: "65%" },
    { header: "Price", width: "17%", xswidth: "17%" },
    { header: "Total", width: "16%", xswidth: "15%" },
  ],
  footer_menu: [
    { name: "FAQ", href: "/faq" },
    { name: "Terms & Conditions", href: "/termsconditions" },
    { name: "Privacy Policy", href: "/privacypolicy" },
    { name: "Terms of use", href: "/termsofuse" },
    { name: "Contact Us", href: "/#contact", sectionId: "contact" },
  ],
  payment_card: [
    { type: <Stripe /> },
    { type: <AmericanExpress /> },
    { type: <Mastercard /> },
    { type: <Visa /> },
    { type: <Paypal /> },
  ],
  social_links: [
    { href: "https://www.facebook.com/gotheextramilemcq", icon: <Facebook /> },
    {
      href: "https://www.linkedin.com/in/sumesh-arora-78346b21",
      icon: <LinkedIn />,
    },
    { href: "https://twitter.com/sumesharora8", icon: <Twitter /> },
  ],
};
