import React, { useContext, useState } from "react";
import { Box, Typography, Button, InputAdornment } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { createStyles, makeStyles } from "@mui/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import axiosInstance from "src/helpers/context/axiosInstance";
import { URLS } from "src/helpers/constants/url";
import { InputTextField } from "../UI/textfield";
import PreLoader from "src/component/Loader";
import "./cart.scss";
import { IntlContext } from "src/App";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#F9F8F6",
      padding: "18px",
      minHeight: "460px",
      "@media (max-width:899px)": {
        marginTop: "20px",
        minHeight: "380px",
      },
      "& .semibold_text": {
        fontWeight: "600",
      },
      "& .orderSummary": {
        fontSize: "22.5px",
        lineHeight: "28px",
        fontWeight: "500",
        paddingBottom: "22px",
        // "@media (max-width:1199px)": {
        //     fontSize: "20px",
        //     lineHeight: "26px",
        // },
        "@media (max-width:899px)": {
          fontSize: "20px",
          lineHeight: "24px",
          paddingBottom: "20px",
        },
      },
      "& .sub_total": {
        lineHeight: "19px",
        fontWeight: "600",
        color: "#707070",
        // fontSize: "16px",
        "@media (max-width:899px)": {
          fontSize: "16px",
        },
        "@media (max-width:599px)": {
          fontSize: "14px",
        },
      },
      "& .cart_total": {
        color: "#000",
        // "@media (max-width:599px)": {
        //     fontSize: "14px",
        // },
      },
      "& .regular_font": {
        fontWeight: "400",
        // "@media (max-width:599px)": {
        //     fontSize: "14px",
        // },
      },
      "& .MuiOutlinedInput-root": {
        maxWidth: "250px",
        "@media (max-width:899px)": {
          maxWidth: "100%",
        },
        "& .MuiOutlinedInput-input": {
          padding: "13.5px 0 13.5px 13.5px",
        },
        "& svg": {
          width: "20px",
          height: "20px",
          "@media (max-width:599px)": {
            width: "17px",
            height: "17px",
          },
        },
      },
      "& input::-webkit-input-placeholder": {
        color: "#707070",
        fontSize: "18px",
        "@media (max-width:899px)": {
          fontSize: "16px",
        },
        "@media (max-width:599px)": {
          fontSize: "14px",
        },
      },
    },
  })
);

const OrderSummary = ({ cart, cartTotal, setCartTotalAmount }) => {
  const classes = useStyles();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const [loader, setLoader] = useState(false);
  const [subTotal, setSubTotal] = useState("0.00");
  const [expiredCoupon, setExpiredCoupon] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [discount, setDiscount] = useState("0.00");
  const [succesMsg, setSuccesMsg] = useState(false);

  const GSTAmount = ((cartTotal * cart?.gst?.split("%")[0]) / 100).toFixed(2);
  const orderTotal = (parseFloat(cartTotal) + parseFloat(GSTAmount)).toFixed(2);

  return (
    <>
      {loader && <PreLoader />}
      <Grid item size={{ xs: 12, md: 4, lg: 4 }}>
        {/* <Grid item xs={12} md={3.77}> */}
        <Box className={classes.root}>
          <Typography className="orderSummary">
            {gothe_mile?.cart?.orderSummary}
          </Typography>
          <Box className="flex_content" sx={{ pb: "9px" }}>
            <Typography className="sub_total">
              {gothe_mile?.cart?.subtotal}
            </Typography>
            <Typography className="cart_total sub_total">
              {"$" + cartTotal}
            </Typography>
          </Box>
          <Box className="flex_content" sx={{ pb: "9px" }}>
            <Typography className="sub_total regular_font">
              {gothe_mile?.cart?.gst} {cart?.gst && `(${cart?.gst})`}
            </Typography>
            <Typography className="cart_total sub_total regular_font">
              {GSTAmount ? "$" + GSTAmount : "$0.00"}
            </Typography>
          </Box>
          <Box className="flex_content" sx={{ pb: "9px" }}>
            <Typography className="sub_total">
              {gothe_mile?.cart?.discount}
            </Typography>
            <Typography className="cart_total sub_total">
              {"$" + discount}
            </Typography>
          </Box>
          <Box className="flex_content" sx={{ pb: "9px" }}>
            <Typography className="sub_total">
              {gothe_mile?.cart?.orderTotal}
            </Typography>
            <Typography className="cart_total sub_total">
              {/* {discount > 0 ? "$" + subTotal : "$" + cartTotal} */}
              {discount > 0 ? "$" + subTotal : "$" + orderTotal}
            </Typography>
          </Box>
          <Box sx={{ mt: { xs: "12px", md: "14px", lg: "18px" } }}>
            <Formik
              initialValues={{ couponCode: "" }}
              validationSchema={Yup.object().shape({
                couponCode: Yup.string().required(
                  gothe_mile?.validation?.couponCode
                ),
              })}
              onSubmit={async (data) => {
                let payload = {
                  couponCode: data?.couponCode,
                  subTotal: orderTotal,
                };
                setLoader(true);
                try {
                  const { data, status } = await axiosInstance.post(
                    URLS?.getDiscount,
                    payload
                  );
                  if (status === 200) {
                    if (data?.value === true) {
                      if (data?.data === "coupon has expired") {
                        setLoader(false);
                        setExpiredCoupon(true);
                        setDiscount("0.00");
                        setInvalid(false);
                        setTimeout(() => {
                          setExpiredCoupon(false);
                        }, 3000);
                      } else {
                        setLoader(false);
                        setDiscount(data?.data?.perDiscount?.toFixed(2));
                        setSubTotal(data?.data?.discount?.toFixed(2));
                        setCartTotalAmount(data?.data);
                        setSuccesMsg(true);
                        setTimeout(() => {
                          setSuccesMsg(false);
                        }, 2500);
                        setInvalid(false);
                        // setTimeout(() => {
                        //   setInvalid(true);
                        // }, 2000);
                        setExpiredCoupon(false);
                      }
                    } else {
                      setLoader(false);
                      setInvalid(true);
                      setTimeout(() => {
                        setInvalid(false);
                      }, 3000);
                      setDiscount("0.00");
                      setExpiredCoupon(false);
                    }
                  } else {
                    setLoader(false);
                  }
                } catch (error) {
                  setLoader(false);
                }
              }}
            >
              {({
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                values,
                resetForm,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box sx={{ m: "12px 0 17px" }}>
                    <Box
                      className="flex_content"
                      sx={{ alignItems: "flex-start" }}
                    >
                      <InputTextField
                        variant="outlined"
                        // className={classes.root}
                        placeholder="Enter Discount Code"
                        name="couponCode"
                        value={values?.couponCode}
                        error={Boolean(
                          touched?.couponCode && errors?.couponCode
                        )}
                        helperText={touched?.couponCode && errors?.couponCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          endAdornment: (
                            <>
                              {discount > 0 ? (
                                <InputAdornment position="end">
                                  <CloseIcon
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setDiscount("0.00");
                                      setCartTotalAmount({});
                                      resetForm();
                                    }}
                                  />
                                </InputAdornment>
                              ) : (
                                ""
                              )}
                            </>
                          ),
                        }}
                      />
                      <Button
                        type="submit"
                        variant="apply_btn"
                        sx={{ ml: { xs: "12px", md: "8px" } }}
                      >
                        {gothe_mile?.cart?.apply}
                      </Button>
                    </Box>
                    {succesMsg && (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "#248F17",
                        }}
                      >
                        {gothe_mile?.message?.success_coupon}
                      </Typography>
                    )}
                    {values?.couponCode !== "" && invalid && (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "#C01515",
                        }}
                      >
                        {gothe_mile?.message?.valid_coupon}
                      </Typography>
                    )}
                    {values?.couponCode !== "" && expiredCoupon && (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "#C01515",
                        }}
                      >
                        {gothe_mile?.message?.expired_coupon}
                      </Typography>
                    )}
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Grid>
    </>
  );
};
export default OrderSummary;
