import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ReactComponent as Rarrow } from "src/assets/images/icons/arrow-right.svg";
import { IntlContext } from "src/App";
import axiosInstance from "src/helpers/context/axiosInstance";
import { URLS } from "src/helpers/constants/url";
import { createStyles, makeStyles } from "@mui/styles";
import PreLoader from "src/component/Loader";
// import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((themt) =>
  createStyles({
    root: {
      "& .sample_test_title_block": {
        padding: "72px 0",
        "@media (max-width:1199px)": {
          padding: "60px 0",
        },
        "@media (max-width:899px)": {
          padding: "48px 0",
        },
        "@media (max-width:599px)": {
          padding: "35px 0",
        },
        "& .freeTestTitle": {
          fontSize: "55px",
          lineHeight: "67px",
          fontWeight: "700",
          color: "#373737",
          paddingBottom: "27px",
          "@media (max-width:1199px)": {
            fontSize: "38px",
            lineHeight: "32px",
          },
          "@media (max-width:899px)": {
            fontSize: "30px",
            lineHeight: "32px",
            paddingBottom: "20px",
          },
          "@media (max-width:599px)": {
            fontSize: "20px",
            lineHeight: "25px",
            paddingBottom: "18px",
          },
        },
      },
      "& .sample_test_block": {
        padding: "51px 0 63px",
        "@media (max-width:1199px)": {
          padding: "40px 0 50px",
        },
        "@media (max-width:899px)": {
          padding: "30px 0 40px",
        },
        "@media (max-width:599px)": {
          padding: "30px 0",
        },
        "& .title": {
          fontSize: "22.5px",
          fontWeight: "600",
          lineHeight: "28px",
          color: "#41A6E9",
          marginBottom: "10px",
          "@media (max-width:1199px)": {
            fontSize: "20px",
            lineHeight: "26px",
          },
          "@media (max-width:899px)": {
            fontSize: "18px",
            lineHeight: "24px",
            marginBottom: "8px",
          },
          "@media (max-width:599px)": {
            fontSize: "16px",
            lineHeight: "20px",
            marginBottom: "6px",
          },
        },
        "& .test_block": {
          padding: "18px 0",
          position: "relative",
          borderTop: "1px solid #E7E9EA",
          "@media (max-width:899px)": {
            padding: "16px 0",
          },
          "@media (max-width:599px)": {
            padding: "14px 0",
          },
          "& .test_title": {
            color: "#101010",
          },
          "& .test_content": {
            lineHeight: "22.5px",
            "@media (max-width:1199px)": {
              lineHeight: "21px",
            },
            "@media (max-width:899px)": {
              lineHeight: "19px",
            },
            "@media (max-width:599px)": {
              lineHeight: "18px",
            },
          },
          "& .take_test_btn": {
            fontWeight: "600",
            padding: "12px 23.5px",
            // maxWidth: "172px",
            // width: "100%",
            "@media (max-width:1199px)": {
              fontSize: "18px",
              lineHeight: "22px",
              padding: "10px 20px",
            },
            "@media (max-width:899px)": {
              fontSize: "16px",
              lineHeight: "20px",
              padding: "8px 18px",
            },
            "@media (max-width:599px)": {
              fontSize: "13px",
              lineHeight: "18px",
              padding: "9px 14px",
            },
          },
          "& .rArrowIcon": {
            marginLeft: "9px",
            width: "20px",
            height: "20px",
            "@media (max-width:1199px)": {
              width: "18px",
              height: "18px",
            },
            "@media (max-width:899px)": {
              width: "16px",
              height: "16px",
            },
            "@media (max-width:599px)": {
              marginLeft: "5px",
              width: "14px",
              height: "14px",
            },
          },
        },
      },
    },
  })
);

const FreeTest = () => {
  const classes = useStyles();
  //   const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const [loader, setLoader] = useState(false);
  const [freeTest, setFreeTest] = useState([]);

  const findFreeTest = async () => {
    setLoader(true);
    try {
      const { data, status } = await axiosInstance.post(URLS.findFreeTest);
      if (status === 200) {
        setLoader(false);
        const testAlphabeticalOrder = data?.data?.sort((a, b) =>
          a?.name.localeCompare(b?.name)
        );
        setFreeTest(testAlphabeticalOrder);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    findFreeTest();
  }, []);

  return (
    <>
      {loader && <PreLoader />}
      <Box className={classes.root}>
        <Box className="edicC_banner sample_test_title_block">
          <Box className="sub_container">
            <Typography className="freeTestTitle">
              {gothe_mile?.freeTest?.title}
            </Typography>
            <Typography className="content">
              {gothe_mile?.freeTest?.content}
            </Typography>
          </Box>
        </Box>
        <Box className="sub_container">
          <Box className="sample_test_block">
            <Typography className="title">
              {gothe_mile?.freeTest?.subTitle}
            </Typography>
            {freeTest?.map((item, id) => {
              return (
                <>
                  <Box key={id} className="test_block">
                    <Grid
                      container
                      spacing={{ xs: 3, sm: 2 }}
                      sx={{ alignItems: "center" }}
                    >
                      <Grid item size={{ xs: 12, sm: 8 }}>
                        <Typography className="title test_title">
                          {item?.name}
                        </Typography>
                        <Typography className="test_content">
                          {item?.description}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        size={{ xs: 12, sm: 4 }}
                        sx={{ textAlign: "right" }}
                      >
                        <Button
                          variant="blue"
                          className="take_test_btn"
                          href={`/test-form/${item?.classMarkFormId}/${item?._id}`}
                        >
                          {gothe_mile?.take_test}
                          <Rarrow className="rArrowIcon" />
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              );
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default FreeTest;
