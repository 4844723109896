import React, { useContext, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { createStyles, makeStyles } from "@mui/styles";
import axiosInstance from "src/helpers/context/axiosInstance";
import { URLS } from "src/helpers/constants/url";
import "./cart.scss";
import { IntlContext } from "src/App";
import PreLoader from "src/component/Loader";
import MessageBox from "../UI/MessageBox";
import { identifiers } from "src/helpers/constants/identifier";
import { ReactComponent as RightArrow } from "src/assets/images/icons/right_a.svg";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& .MuiTable-root": {
        "@media (max-width:599px)": {
          // width: "500px",
        },
        "@media (max-width:420px)": {
          width: "380px",
        },
        "& .MuiTableHead-root": {
          backgroundColor: "#F9F8F6",
          "& .MuiTypography-root": {
            color: "#707070",
            lineHeight: "19px",
            "@media (max-width:1199px)": {
              lineHeight: "17px",
            },
            "@media (max-width:899px)": {
              lineHeight: "15px",
              fontSize: "14px",
            },
            "@media (max-width:599px)": {
              lineHeight: "13px",
            },
          },
          "& .MuiTableRow-head": {
            "& .MuiTableCell-root": {
              border: "0",
              padding: "11px 22px",
            },
          },
        },
        "& .MuiTableBody-root ": {
          "& .MuiTableRow-root": {
            verticalAlign: "top",
            "&:nth-of-type(even)": {
              backgroundColor: "#F7F7F7",
            },

            "& .MuiTableCell-root": {
              color: "#707070",
              border: "0",
              lineHeight: "22px",
              fontSize: "16px",
              lineHeight: "19px",
              padding: "16px 18px",
              "& .remove_": {
                display: "block",
                marginTop: "9px",
                color: "#41A6E9",
                textDecoration: "underline",
                textDecorationColor: "#41A6E9",
                cursor: "pointer",
              },
              "&:last-child": {
                fontWeight: "600",
              },
              "@media (max-width:1199px)": {
                fontSize: "16px",
                lineHeight: "20px",
              },
              "@media (max-width:899px)": {
                fontSize: "14px",
                lineHeight: "18px",
              },
              "@media (max-width:599px)": {
                fontSize: "12px",
                lineHeight: "16px",
              },
            },
          },
        },
      },
      "& .MuiOutlinedInput-root": {
        padding: "10px 11px 10px 8px",
        border: "1px solid #B0B0B0",
        height: "44px",
        "@media (max-width:1199px)": {
          padding: "9px",
          height: "42px",
        },
        "@media (max-width:899px)": {
          padding: "8px",
          height: "38px",
        },
        "@media (max-width:599px)": {
          height: "36px",
        },
        "& .MuiOutlinedInput-input": {
          fontSize: "18px",
          padding: "0px",
          height: "22px",
          color: "#373737",
          "@media (max-width:1199px)": {
            fontSize: "16px",
          },
          "@media (max-width:899px)": {
            fontSize: "14px",
          },
          "@media (max-width:599px)": {},
        },
      },
    },
    root_c: {
      textAlign: "center",
      marginTop: "25px",
      "@media (max-width:899px)": {
        marginTop: "20px",
      },
      "@media (max-width:599px)": {
        marginTop: "16px",
      },
      "& .continue_btn": {
        fontSize: "18px",
        lineHeight: "22px",
        letterSpacing: "0.36px",
        height: "54px",
        maxWidth: "197px",
        width: "100%",
        padding: "16px",
        "& svg": {
          "@media (max-width:899px)": {
            width: "18px",
            height: "18px",
          },
          "@media (max-width:599px)": {
            width: "16px",
            height: "16px",
          },
        },
        "@media (max-width:1199px)": {
          height: "50px",
        },
        "@media (max-width:899px)": {
          fontSize: "16px",
          lineHeight: "20px",
          height: "45px",
          padding: "14px",
        },
        "@media (max-width:599px)": {
          fontSize: "14px",
          lineHeight: "18px",
          height: "40px",
          padding: "12px",
        },
      },
    },
    root_d: {
      "& .MuiButton-root": {
        height: "40px",
        fontSize: "20px",
        "@media (max-width:899px)": {
          fontSize: "18px",
        },
        "@media (max-width:599px)": {
          height: "32px",
          fontSize: "14px",
        },
      },
    },
  })
);

const Cart = ({ getCartItem, cart, cartItem, setCartItem, expandNext }) => {
  const classes = useStyles();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const [messageBox, setMessageBox] = useState(false);
  const [filterCartItem, setFilterCartItem] = useState([]);
  const [loader, setLoader] = useState(false);

  const closeMessageBox = () => {
    setMessageBox(false);
  };

  const updateCart = (id) => {
    setMessageBox(true);
    const filterItem = cartItem.filter((item, index) => {
      return id !== index;
    });
    setFilterCartItem(filterItem);
  };

  const removeCartItem = async () => {
    let payload = {
      createdAt: cart?.createdAt,
      orderId: cart?.orderId,
      productForm: filterCartItem,
      updatedAt: cart?.updatedAt,
      user: cart?.user,
      __v: cart?.__v,
      _id: cart?._id,
    };
    setLoader(true);
    try {
      const { status } = await axiosInstance.post(URLS.removecartItem, payload);
      if (status === 200) {
        getCartItem();
        setLoader(false);
        setCartItem(filterCartItem);
        setMessageBox(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const RemoveItemPopup = () => {
    return (
      <>
        {loader && <PreLoader />}
        <Box className={classes.root_d}>
          <Typography sx={{ pb: "40px" }}>{gothe_mile?.remove_item}</Typography>
          <Grid container spacing={3} sx={{ justifyContent: "center" }}>
            <Grid item size={{ xs: 4 }}>
              <Button variant="browse_blue" onClick={removeCartItem}>
                {gothe_mile?.yes}
              </Button>
            </Grid>
            <Grid item size={{ xs: 4 }}>
              <Button variant="outline_btn" onClick={closeMessageBox}>
                {gothe_mile?.no}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  return (
    <>
      <MessageBox
        open={messageBox}
        handleClose={closeMessageBox}
        content={<RemoveItemPopup />}
      />
      <Box>
        <TableContainer className={classes.root}>
          <Table className="all-post-table">
            <TableHead>
              <TableRow>
                {identifiers?.table_header?.map((item, id) => {
                  return (
                    <>
                      <TableCell
                        key={id}
                        style={{
                          width: { xs: item?.xswidth, md: item?.width },
                        }}
                      >
                        <Typography>{item?.header}</Typography>
                      </TableCell>
                    </>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {cartItem?.map((data, id) => {
                return (
                  <>
                    <TableRow>
                      <TableCell key={id} className="row_data">
                        {id + 1}
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{ textTransform: "uppercase", color: "#101010" }}
                        >
                          {data?.packageName[0]} - {data?.packageName[1]}
                          {data?.productForm ? (
                            data?.categoryLevel3 &&
                            " - " + data?.categoryLevel3?.name
                          ) : (
                            <span style={{ textTransform: "capitalize" }}>
                              {data?.categoryLevel3 &&
                                " - " + data?.categoryLevel3?.name}
                            </span>
                          )}
                          {data?.productForm && " - " + data?.packageName[2]}
                          {data?.productForm && (
                            <span style={{ textTransform: "capitalize" }}>
                              {" - " + data?.productForm?.name}
                            </span>
                          )}
                        </Typography>
                        <Link
                          className="remove_"
                          onClick={() => updateCart(id)}
                        >
                          {gothe_mile?.remove}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {data?.categoryLevel2 &&
                          "$" + data?.categoryLevel2?.price}
                        {data?.categoryLevel3 &&
                          "$" + data?.categoryLevel3?.price}
                        {data?.productForm && "$" + data?.productForm?.price}
                      </TableCell>
                      <TableCell>
                        {data?.categoryLevel2 &&
                          "$" + data?.categoryLevel2?.price}
                        {data?.categoryLevel3 &&
                          "$" + data?.categoryLevel3?.price}
                        {data?.productForm && "$" + data?.productForm?.price}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className={classes.root_c}>
          <Button
            variant="blue"
            onClick={() => expandNext("panel1")}
            className="continue_btn"
          >
            {gothe_mile?.continue}
            <RightArrow />
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default Cart;
