import React, { useContext } from "react";
import { Box, Typography, Link, Breadcrumbs } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { createStyles, makeStyles } from "@mui/styles";
import faqIcon from "src/assets/images/FAQs-amico.png";
import { IntlContext } from "src/App";
import TabsComponent from "../UI/Tabs";
import SubscriptionQuery from "./SubscriptionQuery";
import GeneralQuery from "./GeneralQuery";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: "#2E2E2E",
      padding: "80px 0 58px",
      "@media (max-width:1199px )": {
        padding: "60px 0 48px",
      },
      "& .MuiTypography-root": {
        color: "#ffffff",
      },
      "& .breadcrumb-wrap": {
        "& li": {
          color: "#fff",
        },
      },
      "& .faq_subtitle": {
        fontSize: "22px",
        lineHeight: "27px",
        paddingTop: "37px",
        "@media (max-width:1199px )": {
          fontSize: "20px",
          lineHeight: "24px",
          paddingTop: "30px",
        },
        "@media (max-width:899px )": {
          fontSize: "18px",
          lineHeight: "20px",
          paddingTop: "20px",
        },
        "@media (max-width:599px )": {
          fontSize: "16px",
          lineHeight: "18px",
          paddingTop: "15px",
        },
      },
    },
    root_q: {
      padding: "36px 0 65px",
      "@media (max-width:599px )": {
        padding: "30px 0 50px",
      },
      "& .readquestion_title": {
        fontSize: "26px",
        lineHeight: "26px",
        fontWeight: "600",
        "@media (max-width:1199px )": {
          fontSize: "24px",
          lineHeight: "24px",
        },
        "@media (max-width:899px )": {
          fontSize: "22px",
          lineHeight: "22px",
        },
        "@media (max-width:599px )": {
          fontSize: "20px",
          lineHeight: "20px",
        },
      },
    },
    root_a: {
      "& .MuiAccordionSummary-root": {
        flexDirection: "row-reverse",
        padding: "0px",
        "& .MuiAccordionSummary-content": {
          margin: "20px 0",
          paddingLeft: "20px",
          "& .MuiTypography-root": {
            fontSize: "20px",
            fontWeight: "600",
            lineHeight: "26px",
            "@media (max-width:1199px)": {
              fontSize: "18px",
              lineHeight: "24px",
            },
            "@media (max-width:899px)": {
              fontSize: "16px",
              lineHeight: "22px",
            },
            "@media (max-width:599px)": {
              fontSize: "14px",
              lineHeight: "20px",
            },
          },
          "&.Mui-expanded": {
            margin: "20px 0 0",
          },
        },
        "& svg": {
          width: "34px",
          height: "34px",
          "@media (max-width:1199px )": {
            width: "30px",
            height: "30px",
          },
          "@media (max-width:899px )": {
            width: "25px",
            height: "25px",
          },
          "@media (max-width:599px )": {
            width: "20px",
            height: "20px",
          },
        },
        "&.Mui-expanded": {
          "& svg": {
            "& #Path_94": {
              display: "none",
            },
          },
        },
      },
      "& .MuiAccordionDetails-root": {
        padding: "12px 0px 16px",
        "& .MuiTypography-root": {
          fontSize: "16px",
          letterSpacing: "0.16px",
          lineHeight: "30px",
          "@media (max-width:1199px)": {
            fontSize: "14px",
            lineHeight: "28px",
          },
          "@media (max-width:899px)": {
            fontSize: "12px",
            lineHeight: "26px",
          },
          "@media (max-width:599px)": {
            fontSize: "12px",
            lineHeight: "24px",
          },
        },
        "& ul": {
          "& li": {
            fontSize: "16px",
            letterSpacing: "0.16px",
            lineHeight: "30px",
            color: "#101010",
            fontWeight: "400",
            "@media (max-width:1199px)": {
              fontSize: "14px",
              lineHeight: "28px",
            },
            "@media (max-width:899px)": {
              fontSize: "12px",
              lineHeight: "26px",
            },
            "@media (max-width:599px)": {
              fontSize: "12px",
              lineHeight: "24px",
            },
          },
        },
        "& .ul-lower-alpha": {
          margin: "0px",
          listStyleType: "lower-alpha",
        },
      },
      "& .MuiPaper-root": {
        "&:before": {
          backgroundColor: "#D4D4D4",
          opacity: "1!important",
        },
      },
    },
  })
);

const Faq = () => {
  const classes = useStyles();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;

  const tabItems = [
    {
      label: "General",
      subcontent: "Your general queries",
      content: (
        <Box className={classes.root_a}>
          <GeneralQuery />
        </Box>
      ),
    },
    {
      label: "Subscription",
      subcontent: "Queries regarding payment & test",
      content: (
        <Box className={classes.root_a}>
          <SubscriptionQuery />
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box className={classes.root}>
        <Box className="main_container">
          <Breadcrumbs
            separator="›"
            aria-label="breadcrumb"
            className="breadcrumb-wrap"
          >
            <Link underline="hover" color="inherit" href="/">
              {gothe_mile?.home?.home_text}
            </Link>
            <Typography>{gothe_mile?.faq?.breadCrumbs}</Typography>
          </Breadcrumbs>
          <Grid container spacing={5} sx={{ justifyContent: "space-between" }}>
            <Grid
              item
              size={{ xs: 12, md: 5 }}
              order={{ md: 2 }}
              sx={{
                textAlign: { xs: "center", md: "end" },
                mr: { lg: "-30px" },
              }}
            >
              <img src={faqIcon} alt="FAQ Icon" />
            </Grid>
            <Grid
              item
              size={{ xs: 12, md: 7 }}
              order={{ md: 1 }}
              sx={{
                mt: { md: "80px" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <Typography className="my_cart_title">
                {gothe_mile?.faq?.title}
              </Typography>
              <Typography className="faq_subtitle">
                {gothe_mile?.faq?.subtitle}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className="main_container">
        <Box className={classes.root_q}>
          <Typography className="readquestion_title">
            {gothe_mile?.faq?.read_question}
          </Typography>
          <TabsComponent tabs={tabItems} />
        </Box>
      </Box>
    </>
  );
};
export default Faq;
