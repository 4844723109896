import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import { InputTextField } from "../../component/UI/textfield";
import { createStyles, makeStyles } from "@mui/styles";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import axiosInstance from "src/helpers/context/axiosInstance";
import PreLoader from "src/component/Loader";
import { URLS } from "src/helpers/constants/url";
import MessageBox from "../../component/UI/MessageBox";
import { EventEmitter } from "src/helpers/context/EventEmitter";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& .MuiTypography-root": {
        marginBottom: "30px",
        "@media (max-width:899px)": {
          marginBottom: "25px",
        },
      },
      "& .input_box": {
        marginBottom: "20px",
        "& .MuiOutlinedInput-root": {
          paddingLeft: "0px",
          "& .MuiSvgIcon-root": {
            fill: "#373737",
            width: "32px",
            height: "32px",
            "@media (max-width:1199px)": {
              width: "28px",
              height: "28px",
            },
            "@media (max-width:899px)": {
              width: "25px",
              height: "25px",
            },
            "@media (max-width:599px)": {
              width: "20px",
              height: "20px",
            },
          },
          "& .MuiOutlinedInput-input": {
            padding: "10px",
            height: "20px",
            "@media (max-width:1199px)": {
              height: "18px",
              fontSize: "16px",
            },
            "@media (max-width:899px)": {
              height: "16px",
            },
            "@media (max-width:599px)": {
              height: "14px",
              fontSize: "14px",
            },
          },
        },
      },
      "& .MuiButton-root": {
        fontSize: "20px",
        height: "50px",
        maxWidth: "200px",
        width: "100%",
        "@media (max-width:1199px)": {
          fontSize: "18px",
          lineHeight: "16px",
          height: "45px",
        },
        "@media (max-width:899px)": {
          fontSize: "16px",
          lineHeight: "14px",
          padding: "12px",
          height: "40px",
        },
        "@media (max-width:599px)": {
          fontSize: "13px",
          height: "35px",
          lineHeight: "16px",
        },
      },
    },
  })
);

const ChangePassword = ({ userId, closePopup }) => {
  const classes = useStyles();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const [loader, setLoader] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {loader && <PreLoader />}
      <MessageBox
        open={successMsg}
        handleClose={() => setSuccessMsg(false)}
        content={
          <Typography>{gothe_mile?.message?.password_changed}</Typography>
        }
      />
      <Box className={classes.root}>
        <Formik
          initialValues={{
            password: "",
            password_a: "",
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .min(6, "Min 6 characters required.")
              .required("Password is required"),
            // password_a: Yup.string()
            //   .required('field is required')
            //   .when('password', {
            //     is: (val) => (val && val.length > 0 ? true : false),
            //     then: Yup.string().oneOf(
            //       [Yup.ref('password')],
            //       'Password does not match'
            //     ),
            //   }),
            password_a: Yup.string()
              .required("field is required")
              .oneOf([Yup.ref("password"), null], "Password does not match"),
          })}
          onSubmit={async (data) => {
            let payload = {
              password: data?.password,
              _id: userId,
            };
            setLoader(true);
            try {
              const { data, status } = await axiosInstance.post(
                URLS.forgotPasswordSave,
                payload
              );
              setLoader(false);
              setSuccessMsg(true);
              setTimeout(() => {
                setSuccessMsg(false);
                EventEmitter.dispatch("processafterchangepassword");
              }, 3000);
            } catch (error) {
              setLoader(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ mb: { xs: "10px", md: "20px", lg: "30px" } }}>
                <Box className="input_box">
                  <InputTextField
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder={gothe_mile?.password}
                    value={values?.password}
                    onBlur={handleBlur}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box className="input_box">
                  <InputTextField
                    fullWidth
                    type={showRepeatPassword ? "text" : "password"}
                    name="password_a"
                    placeholder="Enter Password again"
                    value={values?.password_a}
                    onBlur={handleBlur}
                    error={Boolean(touched.password_a && errors.password_a)}
                    helperText={touched.password_a && errors.password_a}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowRepeatPassword(!showRepeatPassword)
                            }
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showRepeatPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                sx={{ justifyContent: "center" }}
              >
                <Grid item size={{ xs: 5, sm: 4 }}>
                  <Button type="submit" variant="browse_blue">
                    {gothe_mile?.submit}
                  </Button>
                </Grid>
                <Grid item size={{ xs: 5, sm: 4 }}>
                  <Button variant="outline_btn" onClick={closePopup}>
                    {gothe_mile?.cancel}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
};
export default ChangePassword;
