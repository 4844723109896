import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Breadcrumbs,
  Link,
  Card,
  CardMedia,
  CardContent,
  CardActions,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import OmnibusBanner from "../UI/OmnibusBanner";
import "../commonComp.scss";
import { Config } from "src/helpers/context/config";
import { IntlContext } from "src/App";
import { URLS } from "src/helpers/constants/url";
import axiosInstance from "src/helpers/context/axiosInstance";
import { useLocation } from "react-router-dom";
import PreLoader from "src/component/Loader";

const Category = () => {
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const location = useLocation();
  const [categoryName, setCategoryName] = useState();
  const [categoryList, setCategoryList] = useState();
  const [loader, setLoader] = useState(false);

  const lastSegment = location?.pathname?.split("/").pop();

  const getCategoryName = async () => {
    let payload = JSON.stringify({
      myslug: lastSegment,
    });
    setLoader(true);
    try {
      const { data, status } = await axiosInstance.post(
        URLS.getCategoryName,
        payload
      );
      if (status === 200 && data?.value === true) {
        setLoader(false);
        setCategoryName(data?.data);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const getCategory = async () => {
    let payload = JSON.stringify({
      myslug: lastSegment,
    });
    setLoader(true);
    try {
      const { data, status } = await axiosInstance.post(
        URLS.getCategory,
        payload
      );
      if (status === 200) {
        setLoader(false);
        setCategoryList(data?.data);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };
  useEffect(() => {
    getCategoryName();
    getCategory();
  }, []);

  const primarycontent = () => {
    return (
      <>
        <Typography
          variant="body1"
          className="catergory_description"
          sx={{ pt: { xs: "16px", sm: "20px", md: "27px" } }}
          dangerouslySetInnerHTML={{ __html: categoryName?.description }}
        />
      </>
    );
  };

  return (
    <>
      {loader && <PreLoader />}
      <Box className="edicC_banner">
        <Box className="sub_container">
          <Grid
            container
            spacing={4.3}
            className="flex_content"
            sx={{ justifyContent: { sm: "center", lg: "space-between" } }}
          >
            <Grid item size={{ xs: 12, lg: 6.5 }}>
              <Breadcrumbs
                separator="›"
                aria-label="breadcrumb"
                className="breadcrumb-wrap"
              >
                <Link underline="hover" color="#6A6A6A" href="/">
                  {gothe_mile?.home?.home_text}
                </Link>
                <Typography sx={{ color: "#6A6A6A!important" }}>
                  {categoryName?.name}
                </Typography>
              </Breadcrumbs>
              <OmnibusBanner
                col="7.7"
                xs_col="12"
                title={categoryName?.name}
                content={primarycontent()}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className="sub_container">
        <Box className="omnibus_list_block">
          <Grid container spacing={{ xs: 2, md: 2.3 }}>
            {categoryList?.map((item, id) => {
              return (
                <>
                  <Grid key={id} item size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <Link
                      href={"/category/" + lastSegment + "/" + item?.myslug}
                      sx={{ textDecoration: "none" }}
                    >
                      <Card
                        sx={{
                          height: "100%",
                          minHeight: { xs: "320px", sm: "360px", md: "398px" },
                          border: "1px solid #DEDEDE",
                          boxShadow: "0px 3px 9px 0px rgba(0,0,0,0.08)",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Box
                            sx={{
                              position: "relative",
                              height: { xs: "100px", md: "112px" },
                            }}
                          >
                            <CardMedia
                              component="img"
                              alt="green iguana"
                              height="auto"
                              image={Config?.mediaURL + "=" + item?.imageThumb}
                              sx={{
                                borderRadius: "3px 3px 0 0",
                                maxHeight: { xs: "100px", md: "112px" },
                                height: "100%",
                                position: "absolute",
                                objectFit: "cover",
                                objectPosition: "center",
                              }}
                            />
                            <Box
                              sx={{
                                position: "relative",
                                maxWidth: "178px",
                                margin: "auto",
                                textAlign: "center",
                                display: "flex",
                                alignItems: "center",
                                height: "100%",
                                justifyContent: "center",
                                p: "10px 0",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontWeight: "600",
                                  lineHeight: { xs: "18px" },
                                }}
                              >
                                {item?.name}
                              </Typography>
                            </Box>
                          </Box>
                          <CardContent
                            sx={{
                              p: {
                                xs: "20px",
                                md: "25px",
                                lg: "27px 27px 0px 27px",
                              },
                              pb: {
                                xs: "30px!important",
                                lg: "47px!important",
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#41A6E9",
                                fontSize: {
                                  xs: "18px",
                                  sm: "20px",
                                  md: "20px",
                                  lg: "22.4px",
                                },
                                lineHeight: "30px",
                                pb: "16px",
                              }}
                            >
                              {item?.name}
                            </Typography>
                            <Typography className="primary_exam_content">
                              19 Chapters{" "}
                              <span style={{ color: "#41A6E9" }}>|</span> Over
                              2500 questions
                            </Typography>
                          </CardContent>
                        </Box>
                        <CardActions
                          sx={{ p: { xs: "0 20px 20px", lg: "0 27px 26px" } }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "'Inter', sans-serif",
                              fontWeight: "600",
                              fontSize: {
                                xs: "16px",
                                sm: "18px",
                                md: "20px",
                                lg: "22.4px",
                              },
                              lineHeight: "27px",
                              color: "#101010",
                            }}
                          >
                            {"AU $" + item?.price}
                          </Typography>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default Category;
