import React, { useState, useContext } from "react";
import { Box, Typography, Button, FormHelperText } from "@mui/material";
import { InputTextField } from "../UI/textfield";
import { IntlContext } from "src/App";
import { createStyles, makeStyles } from "@mui/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { saveToStorage } from "src/helpers/context/Storage";
import axiosInstance from "src/helpers/context/axiosInstance";
import { URLS } from "src/helpers/constants/url";
import { loginUser } from "src/helpers/store/Actions/auth";
import PreLoader from "src/component/Loader";
// import MuiPhoneNumber from "material-ui-phone-number";
import PhoneInput from "react-phone-input-2";
import startsWith from "lodash.startswith";
// import 'react-phone-input-2/lib/material.css';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: "relative",
      background: "#F7F7F7",
      borderRadius: "16px",
      padding: "33px 46px 29px 47px",
      "@media (max-width:1199px)": {
        padding: "30px 45px 40px",
      },
      "@media (max-width:899px)": {
        padding: "30px 40px 36px",
      },
      "@media (max-width:599px)": {
        padding: "28px",
      },
      "& .MuiBox-root": {
        marginBottom: "34px",
        "@media (max-width:1199px)": {
          marginBottom: "30px",
        },
        "@media (max-width:899px)": {
          marginBottom: "25px",
        },
        "@media (max-width:599px)": {
          marginBottom: "20px",
        },
      },
      "& .MuiFormHelperText-root": {
        fontSize: "20px",
        fontWeight: "500",
        lineHeight: "24px",
        color: "#373737",
        letterSpacing: "0px",
        fontFamily: "'Montserrat', sans-serif",
        margin: "0px",
        paddingBottom: "14px",
        "@media (max-width:899px)": {
          fontSize: "18px",
          lineHeight: "22px",
        },
        "@media (max-width:599px)": {
          fontSize: "16px",
          lineHeight: "20px",
        },
        "&.Mui-error": {
          fontFamily: "'Montserrat',sans-serif",
          color: "#C01515",
          fontSize: "14px",
          fontWeight: 500,
          margin: "4px 14px 0",
          lineHeight: "19px",
          padding: "0px",
        },
      },
      "& .MuiTypography-root": {
        lineHeight: "24px",
      },
      "& .MuiButton-root": {
        marginTop: "20px",
        lineHeight: "26px",
        maxWidth: "100%",
        height: "69px",
        "@media (max-width:899px)": {
          height: "50px",
          marginTop: "10px",
        },
        "@media (max-width:599px)": {
          height: "40px",
          marginTop: "0px",
          // width: "80%",
        },
      },
      "& .MuiOutlinedInput-root": {
        height: "54px",
        padding: "0px",
        lineHeight: "24px",
        "@media (max-width:1199px)": {
          height: "50px",
          lineHeight: "22px",
        },
        "@media (max-width:899px)": {
          height: "44px",
          lineHeight: "20px",
        },
        "@media (max-width:599px)": {
          height: "40px",
          lineHeight: "18px",
        },
        "& .MuiOutlinedInput-input": {
          padding: "13px",
          fontSize: "20px",
          lineHeight: "24px",
          "@media (max-width:899px)": {
            padding: "10px",
            fontSize: "18px",
            lineHeight: "22px",
          },
          "@media (max-width:599px)": {
            padding: "8px 10px",
            fontSize: "16px",
            lineHeight: "20px",
          },
        },
        "&.Mui-disabled": {
          background: "#F7F7F7",
          border: "none",
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "20px",
          lineHeight: "24px",
          fontWeight: "400",
          "@media (max-width:899px)": {
            fontSize: "18px",
            lineHeight: "22px",
            height: "46px",
          },
          "@media (max-width:599px)": {
            fontSize: "16px",
            lineHeight: "20px",
            height: "40px",
          },
        },
      },
      "& .phoneNumber": {
        width: "100%",
        "& .MuiInput-root": {
          fontSize: "20px",
          lineHeight: "24px",
          color: " #707070",
          border: "1px solid #D4D4D4",
          background: "#fff",
          padding: "7px 7px 7px 13px",
          borderRadius: "8px",
          letterSpacing: "3px",
          "&:before": {
            borderBottom: "none",
          },
          "&:after": {
            borderBottom: "none",
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
          "&.Mui-disabled": {
            "& .MuiInputAdornment-root": {
              display: "none",
            },
            border: "none",
            background: "#F7F7F7",
          },
          "& .MuiIconButton-root": {
            padding: "0px",
            "& svg": {
              width: "28px",
              height: "28px",
            },
          },
          "@media (max-width:899px)": {
            fontSize: "18px",
            lineHeight: "22px",
          },
          "@media (max-width:599px)": {
            fontSize: "16px",
            lineHeight: "20px",
          },
        },
      },
      "& .profile_msg": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#248F17",
        marginTop: "26px",
        textAlign: "center",
        // position: "absolute",
        // bottom: "20px",
        // left: "200px",
        "@media (max-width:899px)": {
          fontSize: "14px",
        },
        "@media (max-width:599px)": {
          fontSize: "12px",
        },
      },
    },
    dropdownClass: {
      "& .MuiPaper-root": {
        //  top: "430px!important",
        left: "749px!important",
        height: "250px",
        maxHeight: "calc(100% - 200px)",
      },
      "& .MuiMenuItem-root": {
        fontSize: "16px",
      },
    },
  })
);

const GeneralInfo = ({ auth }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const [isDisabled, setIsDisabled] = useState(true);
  const [messageBox, setMessageBox] = useState(false);
  const [loader, setLoader] = useState(false);
  const [contactData, setContactData] = useState({});

  const editProfile = (e) => {
    e.preventDefault();
    setIsDisabled(!isDisabled);
  };

  const handleSave = async (data) => {
    let payload = {
      email: data?.email,
      _id: auth?.data?._id,
      name: data?.firstName + " " + data?.lastName,
      firstName: data?.firstName,
      lastName: data?.lastName,
      country_code: auth?.data?.country_code,
      mobile: auth?.data?.mobile,
    };
    if (contactData && Object.keys(contactData).length !== 0) {
      const countryCode = contactData?.dialCode;
      const mobileNumber = data?.mobile?.split(`+${countryCode}`).pop();
      payload["country_code"] = countryCode;
      payload["mobile"] = mobileNumber.trim();
    }
    var requestParameter = {
      _id: auth?.data?._id,
    };
    setLoader(true);
    try {
      const { data, status } = await axiosInstance.post(
        URLS.registerUser,
        payload
      );
      if (status === 200 && data?.value === true) {
        setMessageBox(true);
        try {
          const { data, status } = await axiosInstance.post(
            URLS.changeUserProfile,
            requestParameter
          );
          if (status === 200 && data?.value === true) {
            setLoader(false);
            saveToStorage("authdata", data);
            dispatch(loginUser(data));
          } else {
            setLoader(false);
          }
        } catch (error) {
          setLoader(false);
        }
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <>
      {loader && <PreLoader />}
      <Box className={classes.root}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            // name: auth?.data?.name,
            firstName: auth?.data?.firstName,
            lastName: auth?.data?.lastName,
            email: auth?.data?.email,
            mobile: `+${auth?.data?.country_code}${auth?.data?.mobile}`,
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().trim().required("Name is required"),
            lastName: Yup.string().trim().required("Name is required"),
            email: Yup.string()
              .required("Email is required")
              .email("Must be a valid email")
              .max(255),
            mobile: Yup.string()
              .trim()
              .min(10, "Phone number is not valid")
              .required("Phone number is required"),
          })}
          onSubmit={(values) => {
            handleSave(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            touched,
            setFieldValue,
          }) => (
            <>
              <form onSubmit={handleSubmit}>
                <Box>
                  <FormHelperText className="field_label">
                    {gothe_mile?.firstName}
                  </FormHelperText>
                  <InputTextField
                    disabled={isDisabled}
                    className="disable_field"
                    fullWidth
                    error={Boolean(touched?.firstName && errors?.firstName)}
                    helperText={touched?.firstName && errors?.firstName}
                    type="text"
                    name="firstName"
                    value={
                      isDisabled ? auth?.data?.firstName : values?.firstName
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Box>
                <Box>
                  <FormHelperText className="field_label">
                    {gothe_mile?.lastName}
                  </FormHelperText>
                  <InputTextField
                    disabled={isDisabled}
                    className="disable_field"
                    fullWidth
                    error={Boolean(touched?.lastName && errors?.lastName)}
                    helperText={touched?.lastName && errors?.lastName}
                    type="text"
                    name="lastName"
                    value={isDisabled ? auth?.data?.lastName : values?.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Box>
                <Box>
                  <FormHelperText className="field_label">
                    {gothe_mile?.email_address}
                  </FormHelperText>
                  <InputTextField
                    disabled
                    className="disable_field"
                    fullWidth
                    type="email"
                    name="email"
                    value={auth?.data?.email}
                  />
                </Box>
                <Box>
                  <FormHelperText className="field_label">
                    {gothe_mile?.phone_number}
                  </FormHelperText>
                  <PhoneInput
                    disabled={isDisabled}
                    disableDropdown={isDisabled}
                    // enableLongNumbers={true}
                    // autoFormat={false}
                    className={`phoneNumber ${isDisabled ? "isDisable" : ""}`}
                    // dropdownClass={classes.dropdownClass}
                    country="au"
                    name="mobile"
                    type="text"
                    // disableAreaCodes={true}
                    // placeholder="phone Number"
                    // error={Boolean(touched?.mobile && errors?.mobile)}
                    // helperText={touched?.mobile && errors?.mobile}
                    value={
                      isDisabled
                        ? `+${auth?.data?.country_code.concat(
                            auth?.data?.mobile
                          )}`
                        : values?.mobile
                    }
                    isValid={(inputNumber, country, countries) => {
                      return countries.some((country) => {
                        return (
                          startsWith(inputNumber, country.dialCode) ||
                          startsWith(country.dialCode, inputNumber)
                        );
                      });
                    }}
                    onChange={(value, data) => {
                      setFieldValue("mobile", value);
                      setContactData(data);
                    }}
                    onBlur={handleBlur}
                    specialLabel=""
                  />
                  {Boolean(touched?.mobile && errors?.mobile) && (
                    <Typography className="field-error">
                      {touched?.mobile && errors?.mobile}
                    </Typography>
                  )}
                </Box>
                {isDisabled ? (
                  <Button
                    type="button"
                    variant="browse_blue"
                    onClick={editProfile}
                  >
                    {gothe_mile?.edit}
                  </Button>
                ) : (
                  <Button type="submit" variant="browse_blue">
                    {gothe_mile?.save}
                  </Button>
                )}
                <Typography className="profile_msg">
                  {messageBox && gothe_mile?.message?.profile_changed}
                </Typography>
              </form>
            </>
          )}
        </Formik>
      </Box>
    </>
  );
};
export default GeneralInfo;
