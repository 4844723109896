import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Typography, Avatar, AvatarGroup } from "@mui/material";
import Grid from "@mui/material/Grid2";
import axiosInstance from "src/helpers/context/axiosInstance";
import { URLS } from "src/helpers/constants/url";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Config } from "src/helpers/context/config";
// import { ReactComponent as PlayIcon } from "src/assets/images/icons/play-circle.svg";
import { ReactComponent as MapShield } from "src/assets/images/icons/map-shield.svg";
import { ReactComponent as PlusCircle } from "src/assets/images/icons/feather-plus-circle.svg";
import user1 from "src/assets/images/user1.png";
import user2 from "src/assets/images/user2.png";
import user3 from "src/assets/images/user3.png";
import user4 from "src/assets/images/user4.png";
import user5 from "src/assets/images/user5.png";
import user6 from "src/assets/images/user6.png";

const SliderComp = ({ setLoader, gothe_mile }) => {
  const [sliderData, setSliderData] = useState([]);

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
  };

  const getSliderData = useCallback(async () => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.post(URLS.getSliderData);
      if (status === 200) {
        setLoader(false);
        setSliderData(data?.data?.results);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    getSliderData();
  }, []);

  return (
    <>
      <Box className="slider-wrap ">
        <Slider {...settings}>
          {sliderData?.map((item, id) => {
            return (
              <>
                <Box className="banner_section">
                  <Box className="main_container">
                    <Grid
                      container
                      spacing={3}
                      key={id}
                      style={{ justifyContent: "space-between" }}
                    >
                      <Grid
                        item
                        size={{ xs: 5.6 }}
                        className="banner-content-wrap"
                      >
                        <Typography
                          variant="h1"
                          sx={{
                            minHeight: { xs: "100px", lg: "180px" },
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {item?.name}
                        </Typography>
                        <Box className="btn_wrap">
                          <Button href={item?.link} className="blue_btn br_btn">
                            {item?.button_text}
                          </Button>
                        </Box>
                        <Box className="avtar_trust_block">
                          <Box className="trust_block">
                            <AvatarGroup max={6}>
                              <Avatar
                                className="img_avtar"
                                alt="Remy Sharp"
                                src={user1}
                              />
                              <Avatar
                                className="img_avtar"
                                alt="Travis Howard"
                                src={user2}
                              />
                              <Avatar
                                className="img_avtar"
                                alt="Trevor Henderson"
                                src={user3}
                              />
                              <Avatar
                                className="img_avtar"
                                alt="Agnes Walker"
                                src={user4}
                              />
                              <Avatar
                                className="img_avtar"
                                alt="Trevor Henderson"
                                src={user5}
                              />
                              <Avatar
                                className="img_avtar"
                                alt="Trevor Henderson"
                                src={user6}
                              />
                            </AvatarGroup>
                            <PlusCircle style={{ marginLeft: "12px" }} />
                          </Box>
                          <Box
                            className="trust_block"
                            style={{ paddingTop: "27px" }}
                          >
                            <MapShield style={{ marginRight: "10px" }} />
                            <Typography variant="h5">
                              {gothe_mile?.medicine_slider?.trust_text}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item size={{ xs: 6 }} className="banner-img-wrap">
                        <img
                          className="banner_img"
                          src={Config?.mediaURL + "=" + item?.image}
                          alt="banner_img"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </>
            );
          })}
        </Slider>
      </Box>
    </>
  );
};
export default React.memo(SliderComp);
