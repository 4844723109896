import React from "react";
// import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { createStore } from "redux";
import rootreducer from "./helpers/store/Reducers/rootreducer";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";

const store = createStore(rootreducer);

// const ReactDOM = ReactDOM.createRoot(document.getElementById("root"));
const root = createRoot(document.getElementById("root"));

root.render(
  <>
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
    {/* document.getElementById("root") */}
  </>
);
