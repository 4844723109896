import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Config } from "src/helpers/context/config";
import { useNavigate } from "react-router-dom";
import { IntlContext } from "src/App";
import { createStyles, makeStyles } from "@mui/styles";
import axiosInstance from "src/helpers/context/axiosInstance";
import { URLS } from "src/helpers/constants/url";
import invoice from "src/assets/images/icons/file-invoice-dollar.svg";
import { NavLink } from "react-router-dom";
import moment from "moment";
import TestResultDialogBox from "../UI/TestResultDialogBox";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import PreLoader from "src/component/Loader";
import calendarDay from "src/assets/images/icons/calendar-day.svg";
import up_down_arrow from "src/assets/images/icons/up_down_arrow.svg";

const useStyles = makeStyles((theme) =>
  createStyles({
    root_d: {
      "@media (max-width:1024px)": {
        display: "none",
      },
      "&:last-child": {
        borderRadius: "0 0 20px 20px",
      },
      "& .content_block": {
        marginBottom: "12px",
        "& .MuiTableHead-root": {
          backgroundColor: "#FAEDFF",
        },
        "& .MuiTableBody-root ": {
          "& .MuiTableRow-root": {
            "&:nth-of-type(odd)": {
              backgroundColor: "#F7F7F7",
            },
            "&:nth-of-type(even)": {
              backgroundColor: "#EFEFEF",
            },
          },
        },
        "& .MuiTableCell-root": {
          textAlign: "center",
          borderBottom: "none",
          padding: "14px 0 14px 14px",
        },
        "& .MuiTableCell-body": {
          // padding: "24px 10px",
          padding: "14px 0 14px 14px",
        },
        "& .title": {
          fontWeight: "600",
          lineHeight: "20px",
          color: "#373737",
          textTransform: "uppercase",
          "@media (max-width:899px)": {
            fontSize: "14px",
            lineHeight: "16px",
            // paddingTop: "10px",
          },
          "@media (max-width:599px)": {
            fontSize: "12px",
            lineHeight: "14px",
          },
        },
        "& .test_index": {
          fontWeight: "500",
          textTransform: "lowercase",
        },
        "& .test_name": {
          fontSize: "22px",
          fontWeight: "400",
          // lineHeight: "26px",
          "@media (max-width:1199px)": {
            fontSize: "20px",
            lineHeight: "24px",
          },
          "@media (max-width:899px)": {
            fontSize: "18px",
            lineHeight: "22px",
          },
          "@media (max-width:599px)": {
            fontSize: "16px",
            lineHeight: "20px",
          },
        },
        "& .main_test": {
          fontWeight: "600",
          padding: "24px 0",
          "@media (max-width:1199px)": {
            padding: "20px 0",
          },
        },
        "& .score_label": {
          fontSize: "18px",
          lineHeight: "20px",
          fontWeight: "600",
        },
        "& .not_test": {
          fontWeight: "300",
          color: "#B0B0B0",
        },
        "& .tRtest_btn": {
          fontSize: "16px",
          fontWeight: "600",
          lineHeight: "20px",
          borderRadius: "6px",
          padding: "8px 16px",
          maxWidth: "112px",
          width: "100%",
          marginRight: "21px",
          "@media (max-width:1199px)": {
            fontSize: "14px",
            lineHeight: "18px",
            // width: "140px",
            padding: "8px 14px",
          },
        },
        "& .score_text": {
          fontSize: "16px",
          lineHeight: "26px",
          marginBottom: "15px",
          "@media (max-width:1199px)": {
            lineHeight: "24px",
          },
        },
        "& .shareResult_link": {
          display: "block",
          textDecoration: "underline",
          color: "#405CFB",
          fontSize: "14px",
          lineHeight: "20px",
          paddingTop: "4px",
        },
      },
    },
    root_m: {
      display: "none",
      "@media (max-width:1024px)": {
        width: "100%",
        display: "block",
        marginBottom: "12px",
      },
      "& .omnibus_details": {
        background: "#F6F6F6",
        padding: "18px 0 0",
        "@media (max-width:899px)": {
          padding: "16px 0 0",
        },
        "@media (max-width:599px)": {
          padding: "12px 0 0",
        },
        "& .test_detail": {
          fontSize: "16px",
          lineHeight: "20px",
          fontWeight: "600",
          color: "#373737",
          "@media (max-width:899px)": {
            fontSize: "14px",
            lineHeight: "17px",
          },
          "@media (max-width:599px)": {
            fontSize: "12px",
            lineHeight: "15px",
          },
        },
        "& .test_timedate": {
          fontWeight: "400",
          color: "#000",
        },
        "& .dwld_invoice": {
          fontWeight: "400",
          color: "#405CFB",
          textDecoration: "underline",
        },
      },
      "& .test_wrapper": {
        paddingBottom: "22px",
        "@media (max-width:899px)": {
          paddingBottom: "20px",
        },
        "@media (max-width:599px)": {
          paddingBottom: "18px",
        },
      },
      "& .MuiAccordion-root": {
        "&:before": {
          height: "1px !important",
          background: "#E7E7E7",
        },
        "& .CSummery_block": {
          width: "100%",
          backgroundColor: "#F6F6F6",
          padding: "16px 18px",
          textAlign: "center",
          "& .toggle_arrow": {
            transform: "rotate(90deg)",
            "@media (max-width:899px)": {
              width: "9px",
              height: "12px",
            },
            "@media (max-width:599px)": {
              width: "7px",
              height: "10px",
            },
          },
          "@media (max-width:899px)": {
            padding: "14px 16px",
          },
          "@media (max-width:599px)": {
            padding: "12px 14px",
          },
          "& .test_name": {
            fontSize: "18px",
            lineHeight: "22px",
            fontWeight: "600",
            color: "#373737",
            "@media (max-width:899px)": {
              fontSize: "16px",
              lineHeight: "20px",
            },
            "@media (max-width:599px)": {
              fontSize: "14px",
              lineHeight: "18px",
            },
          },
          "& .title": {
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "16px",
            color: "#373737",
            textTransform: "uppercase",
            "@media (max-width:899px)": {
              fontSize: "12px",
              lineHeight: "14px",
            },
            "@media (max-width:599px)": {
              fontSize: "9px",
              lineHeight: "11px",
            },
          },
        },
        "&.Mui-expanded": {
          "&:before": {
            opacity: "1",
          },
          "& .CSummery_block": {
            borderBottom: "1px solid #E7E7E7",
            "& .toggle_arrow": {
              transform: "rotate(270deg)",
            },
          },
        },
        "& .Caccordian_details": {
          width: "100%",
          backgroundColor: "#F6F6F6",
          padding: "16px 18px 0px",
          textAlign: "center",
          color: "#373737",
          "& .toggle_arrow": {
            transform: "rotate(90deg)",
            "@media (max-width:899px)": {
              width: "8px",
              height: "12px",
            },
            "@media (max-width:599px)": {
              width: "6px",
              height: "10px",
            },
          },
          "@media (max-width:899px)": {
            padding: "14px 16px 0px",
          },
          "@media (max-width:599px)": {
            padding: "12px 14px 0px",
          },
          "& .test_name": {
            fontSize: "18px",
            lineHeight: "22px",
            color: "#373737",
            "@media (max-width:899px)": {
              fontSize: "16px",
              lineHeight: "20px",
            },
            "@media (max-width:599px)": {
              fontSize: "14px",
              lineHeight: "18px",
            },
          },
          "& .title": {
            fontSize: "14px",
            // fontWeight: "400",
            lineHeight: "16px",
            color: "#707070",
            textTransform: "uppercase",
            "@media (max-width:899px)": {
              fontSize: "12px",
              lineHeight: "14px",
            },
            "@media (max-width:599px)": {
              fontSize: "9px",
              lineHeight: "11px",
            },
          },
          "& .test_index": {
            textTransform: "lowercase",
          },
          "& .score_block": {
            border: "1px solid #E7E7E7",
            borderRadius: "3px",
            background: "#fff",
            margin: "12px 0 0 80px",
            textAlign: "left",
            "@media (max-width:899px)": {
              margin: "12px 0 0 50px",
            },
            "@media (max-width:599px)": {
              margin: "12px 0 0 30px",
            },
          },
          "& .score_text": {
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: "600",
            color: "#373737",
            "@media (max-width:899px)": {
              fontSize: "14px",
              lineHeight: "17px",
            },
            "@media (max-width:599px)": {
              fontSize: "12px",
              lineHeight: "15px",
            },
          },
          "& .tRtest_btn": {
            fontSize: "18px",
            fontWeight: "500",
            lineHeight: "22px",
            borderRadius: "6px",
            padding: "8px 16px",
            maxWidth: "230px",
            width: "100%",
            "@media (max-width:899px)": {
              fontSize: "16px",
              lineHeight: "20px",
              padding: "7px 12px",
            },
            "@media (max-width:599px)": {
              fontSize: "14px",
              lineHeight: "18px",
              maxWidth: "100%",
              padding: "6px 10px",
            },
          },
        },
      },
      "& .test_name": {
        fontSize: "18px",
        lineHeight: "22px",
        color: "#373737",
        "@media (max-width:899px)": {
          fontSize: "16px",
          lineHeight: "20px",
        },
        "@media (max-width:599px)": {
          fontSize: "14px",
          lineHeight: "18px",
        },
      },
      "& .title": {
        fontSize: "14px",
        // fontWeight: "400",
        lineHeight: "16px",
        color: "#707070",
        textTransform: "uppercase",
        "@media (max-width:899px)": {
          fontSize: "12px",
          lineHeight: "14px",
        },
        "@media (max-width:599px)": {
          fontSize: "9px",
          lineHeight: "11px",
        },
      },
      "& .test_index": {
        textTransform: "lowercase",
      },
      "& .score_block": {
        border: "1px solid #E7E7E7",
        borderRadius: "3px",
        background: "#fff",
        margin: "16px 0 0 80px",
        textAlign: "left",
        "@media (max-width:899px)": {
          margin: "14px 0 0 50px",
        },
        "@media (max-width:599px)": {
          margin: "12px 0 0 30px",
        },
      },
      "& .score_text": {
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: "600",
        color: "#373737",
        "@media (max-width:899px)": {
          fontSize: "14px",
          lineHeight: "17px",
        },
        "@media (max-width:599px)": {
          fontSize: "12px",
          lineHeight: "15px",
        },
      },
      "& .tRtest_btn": {
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: "22px",
        borderRadius: "6px",
        padding: "8px 16px",
        maxWidth: "230px",
        width: "100%",
        "@media (max-width:899px)": {
          fontSize: "16px",
          lineHeight: "20px",
          padding: "7px 12px",
        },
        "@media (max-width:599px)": {
          fontSize: "14px",
          lineHeight: "18px",
          maxWidth: "100%",
          padding: "6px 10px",
        },
      },
    },
    root_b: {
      "& .result_text": {
        fontSize: "12px",
        color: "#405CFB",
        textTransform: "uppercase",
        lineHeight: "15px",
        paddingBottom: "6px",
      },
      "& .text_align": {
        textAlign: "left",
        alignItems: "center",
      },
      "& .test_name": {
        fontSize: "22.5px",
        lineHeight: "28px",
        fontWeight: "600",
        paddingBottom: "9px",
      },
      "& .user_name": {
        fontSize: "12px",
        lineHeight: "16px",
        color: "#6A6A6A",
      },
      "& .test_feature": {
        lineHeight: "19px",
        color: "#373737",
      },
      "& .result_pending": {
        fontWeight: "700",
        fontStyle: "italic",
      },
      "& .feedback_block": {
        backgroundColor: "#ffff",
        padding: "16px 16px 16px 20px",
        borderRadius: "8px",
        marginTop: "36px",
        position: "relative",
        "&:before": {
          content: "''",
          position: "absolute",
          display: "block",
          background: "#268855",
          width: "8px",
          height: "100%",
          top: "0",
          left: "0",
          borderRadius: "8px 0 0 8px",
        },
        "& .feedback_title": {
          fontSize: "14px",
          fontWeight: "700",
          lineHeight: "18px",
          color: "#373737",
        },
        // "& .feedback_content": {
        //   fontWeight: "400",
        //   marginTop: "6px",
        // },
      },
      "& .cancle_btn": {
        maxWidth: "100px",
        width: "100%",
        height: "45px",
        border: "1px solid #41A6E9",
        color: "#41A6E9",
      },
      "& .share_btn": {
        maxWidth: "100px",
        width: "100%",
        height: "45px",
      },
    },
  })
);

const Dashboard_details = ({
  product,
  auth,
  dashboardData,
  panelVal,
  expandedVal,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const [subProduct, setSubProduct] = useState([]);
  const [testData, setTestData] = useState({});
  const [testResult, setTestResult] = useState({});
  const [resultPopup, setResultPopup] = useState(false);
  const [expanded, setExpanded] = useState("");
  // const [isview, setIsisview] = useState(false);
  const [loader, setLoader] = useState(false);

  const toDay = moment(new Date());
  const remain_days =
    364 - toDay.diff(moment(dashboardData?.orderDate), "days");

  const handleChange = (panel) => (e, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const getLowerCategories = async () => {
    setLoader(true);
    let payload = {
      categoryLevel2: product?.categoryLevel2 && product?.categoryLevel2?._id,
      orderId: dashboardData?._id,
      categoryLevel3: product?.categoryLevel3 && product?.categoryLevel3?._id,
      productForm: product?.productForm && product?.productForm?._id,
      userId: auth?.data?._id,
    };
    let newData = [];
    try {
      const { data, status } = await axiosInstance.post(
        URLS.getLowerCategories,
        payload
      );

      if (status === 200) {
        let i = 0;
        data?.data?.map((test) => {
          let payload = {
            categoryLevel3: test?._id,
            orderId: dashboardData?._id,
            userId: auth?.data?._id,
          };
          axiosInstance
            .post(URLS.getLowerCategories, payload)
            .then((res) => {
              if (res?.status === 200) {
                i++;
                test.subProducts = res?.data?.data;
                if (i === data?.data?.length) {
                  setTimeout(() => {
                    setLoader(false);
                  }, 1000);
                }
              }
            })
            .catch((error) => setLoader(false));
          newData.push(test);
        });
        setSubProduct(newData);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (panelVal === expandedVal) {
      getLowerCategories();
      // setTimeout(() => {
      //   setIsisview(true);
      // }, 3000);
    } else if (expandedVal === false) {
      // setIsisview(false);
    }
  }, [expandedVal]);
  const getTestResultData = (item, score) => {
    setTestData(item);
    setTestResult(score);
    setResultPopup(true);
  };
  var dayName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const IsoStartDate = new Date(testResult?.startDate);
  const sDay = dayName[IsoStartDate?.getDay()];
  const sDate = IsoStartDate?.getDate();
  const sMonthName = month[IsoStartDate?.getMonth()];
  const sYear = IsoStartDate?.getFullYear().toString().slice(-2);
  const sHour = IsoStartDate?.getHours();
  const sMinitue = String(IsoStartDate?.getMinutes()).padStart(2, "0");
  const testStartDate =
    sDay +
    " " +
    sDate +
    " " +
    sMonthName +
    " " +
    "'" +
    sYear +
    " " +
    sHour +
    ":" +
    sMinitue;

  const IsoEndDate = new Date(testResult?.testEndDate);
  const eDay = dayName[IsoEndDate?.getDay()];
  const eDate = IsoEndDate?.getDate();
  const eMonthName = month[IsoEndDate?.getMonth()];
  const eYear = IsoEndDate?.getFullYear().toString().slice(-2);
  const eHour = IsoEndDate?.getHours();
  const eMinitue = String(IsoEndDate?.getMinutes()).padStart(2, "0");
  const testEndDate =
    eDay +
    " " +
    eDate +
    " " +
    eMonthName +
    " " +
    "'" +
    eYear +
    " " +
    eHour +
    ":" +
    eMinitue;

  const baseShareURL = `${
    Config?.appBaseURL +
    "testresult/?testId=" +
    testData?._id +
    "&orderId=" +
    dashboardData?._id +
    "&userId=" +
    auth?.data?._id +
    "&testResultId=" +
    testResult?._id
  }`;

  const testScore = [
    { label: "Score 1" },
    { label: "Score 2" },
    { label: "Score 3" },
  ];

  const TestResultContent = () => {
    return (
      <>
        <Box className={classes.root_b}>
          <Typography className="result_text">
            {gothe_mile?.TestResults?.results}
          </Typography>
          <Typography className="test_name">{testData?.name}</Typography>
          <Typography className="user_name">{auth?.data?.name}</Typography>
          <Box sx={{ pt: "27px" }}>
            <Grid container className="text_align">
              <Grid item size={{ xs: 4.5, md: 4 }} sx={{ pb: "6px" }}>
                <Typography className="test_feature">
                  {gothe_mile?.TestResults?.points}
                </Typography>
              </Grid>
              <Grid item size={{ xs: 6 }} sx={{ pb: "6px" }}>
                <Typography sx={{ fontWeight: "700" }}>
                  {testResult?.testScore && testResult?.testScoreAvailable ? (
                    testResult?.testScore + "/" + testResult?.testScoreAvailable
                  ) : (
                    <Typography className="result_pending">
                      {gothe_mile?.TestResults?.pending}
                    </Typography>
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="text_align">
              <Grid item size={{ xs: 4.5, md: 4 }} sx={{ p: "6px 0" }}>
                <Typography className="test_feature">
                  {gothe_mile?.TestResults?.percentage}
                </Typography>
              </Grid>
              <Grid item size={{ xs: 6 }} sx={{ p: "6px 0" }}>
                <Typography sx={{ fontWeight: "700" }}>
                  {testResult?.testPercentage ? (
                    testResult?.testPercentage + "%"
                  ) : (
                    <Typography className="result_pending">
                      {gothe_mile?.TestResults?.pending}
                    </Typography>
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="text_align">
              <Grid item size={{ xs: 4.5, md: 4 }} sx={{ p: "6px 0" }}>
                <Typography className="test_feature">
                  {gothe_mile?.TestResults?.duration}
                </Typography>
              </Grid>
              <Grid item size={{ xs: 6 }} sx={{ p: "6px 0" }}>
                <Typography sx={{ fontWeight: "700" }}>
                  {testResult?.testDuration ? (
                    testResult?.testDuration
                  ) : (
                    <Typography className="result_pending">
                      {gothe_mile?.TestResults?.pending}
                    </Typography>
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="text_align">
              <Grid item size={{ xs: 4.5, md: 4 }} sx={{ p: "6px 0" }}>
                <Typography className="test_feature">
                  {gothe_mile?.TestResults?.dateStarted}
                </Typography>
              </Grid>
              <Grid item size={{ xs: 6 }} sx={{ p: "6px 0" }}>
                <Typography sx={{ fontWeight: "700" }}>
                  {testStartDate ? (
                    testStartDate
                  ) : (
                    <Typography className="result_pending">
                      {gothe_mile?.TestResults?.pending}
                    </Typography>
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="text_align">
              <Grid item size={{ xs: 4.5, md: 4 }} sx={{ pt: "6px" }}>
                <Typography className="test_feature">
                  {gothe_mile?.TestResults?.dateFinished}
                </Typography>
              </Grid>
              <Grid item size={{ xs: 6 }} sx={{ pt: "6px" }}>
                <Typography sx={{ fontWeight: "700" }}>
                  {testEndDate ? (
                    testEndDate
                  ) : (
                    <Typography className="result_pending">
                      {gothe_mile?.TestResults?.pending}
                    </Typography>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box className="feedback_block">
            <Box>
              <Typography className="feedback_title">
                {gothe_mile?.TestResults?.feedback}
              </Typography>

              <Typography
                className="feedback_title"
                sx={{ mt: "6px", fontWeight: "400!important" }}
                dangerouslySetInnerHTML={{
                  __html: testResult?.feedback
                    ? testResult?.feedback
                    : gothe_mile?.TestResults?.pending_feedback,
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              pt: "27px",
            }}
          >
            <Button
              className="cancle_btn"
              variant="outline_btn"
              sx={{ mr: "18px" }}
              onClick={() => setResultPopup(false)}
            >
              {gothe_mile?.TestResults?.cancel}
            </Button>
            <FacebookShareButton
              url={baseShareURL}
              style={{ marginRight: "10px" }}
            >
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <WhatsappShareButton
              url={baseShareURL}
              style={{ marginRight: "10px" }}
            >
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>
            <TwitterShareButton
              url={baseShareURL}
              style={{ marginRight: "10px" }}
            >
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <LinkedinShareButton url={baseShareURL}>
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
          </Box>
        </Box>
      </>
    );
  };
  return (
    <>
      {/* DESKTOP VIEW */}

      <Box className={classes.root_d}>
        <Box className="content_block">
          {loader ? (
            <PreLoader />
          ) : (
            <TableContainer className={classes.root}>
              <Table className="all-post-table">
                {subProduct?.length > 0 ? (
                  subProduct?.map((item, id) => {
                    return (
                      <>
                        {product?.categoryLevel2 ? (
                          <>
                            <TableHead key={id}>
                              <TableRow>
                                <TableCell style={{ width: "4%" }}>
                                  <Typography className="title test_index">
                                    {id + 1}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  style={{ textAlign: "left", width: "8%" }}
                                >
                                  <Typography className="title">
                                    {gothe_mile?.dashboard?.chapter}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  style={{ width: "26%", textAlign: "left" }}
                                >
                                  <Typography className="main_test test_name">
                                    {item?.name}
                                  </Typography>
                                </TableCell>
                                <TableCell style={{ width: "14%" }}>
                                  <Typography className="score_label">
                                    {gothe_mile?.score1}
                                  </Typography>
                                </TableCell>
                                <TableCell style={{ width: "16%" }}>
                                  <Typography className="score_label">
                                    {gothe_mile?.score2}
                                  </Typography>
                                </TableCell>
                                <TableCell style={{ width: "14%" }}>
                                  <Typography className="score_label">
                                    {gothe_mile?.score3}
                                  </Typography>
                                </TableCell>
                                <TableCell style={{ width: "18%" }}>
                                  <Typography></Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item?.subProducts?.length > 0 ? (
                                item?.subProducts?.map((obj, innerid) => {
                                  return (
                                    <TableRow key={innerid}>
                                      <TableCell style={{ width: "2%" }}>
                                        <Typography className="title test_index">
                                          {String.fromCharCode(
                                            innerid + "a".charCodeAt(0)
                                          ) + ")"}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        className="row_data"
                                        style={{
                                          textAlign: "left",
                                          width: "8%",
                                        }}
                                      >
                                        <Typography className="title">
                                          {gothe_mile?.test}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          textAlign: "left",
                                          width: "30%",
                                        }}
                                      >
                                        <Typography
                                          className="test_name"
                                          sx={{ p: "30px 0" }}
                                        >
                                          {obj?.name}
                                        </Typography>
                                      </TableCell>
                                      {obj?.count === 0 ? (
                                        <>
                                          <TableCell
                                            colSpan={3}
                                            style={{ width: "42%" }}
                                          >
                                            <Typography
                                              className="test_name not_test"
                                              style={{ paddingLeft: "20px" }}
                                            >
                                              {remain_days > 0
                                                ? gothe_mile?.dashboard?.noTest
                                                : "you are not able to take test"}
                                            </Typography>
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              width: "18%",
                                              textAlign: "right",
                                            }}
                                          >
                                            {remain_days > 0 && (
                                              <Button
                                                variant="blue"
                                                className="tRtest_btn"
                                                onClick={() =>
                                                  navigate(
                                                    `/test-form/${obj?.classMarkFormId}/${obj?._id}`,
                                                    {
                                                      state: {
                                                        orderId:
                                                          dashboardData?._id,
                                                      },
                                                    }
                                                  )
                                                }
                                              >
                                                {gothe_mile?.take_test}
                                              </Button>
                                            )}
                                          </TableCell>
                                        </>
                                      ) : (
                                        <>
                                          {obj?.testScore?.length > 0 &&
                                            obj?.testScore?.map(
                                              (score, index) => {
                                                return (
                                                  <>
                                                    <TableCell
                                                      key={index}
                                                      style={{ width: "14%" }}
                                                    >
                                                      <Button variant="test_outline_btn">
                                                        {score?.testPercentage +
                                                          "%"}
                                                      </Button>
                                                      <NavLink
                                                        to="#"
                                                        onClick={() =>
                                                          getTestResultData(
                                                            obj,
                                                            score
                                                          )
                                                        }
                                                        className="shareResult_link"
                                                      >
                                                        {
                                                          gothe_mile?.shareResults
                                                        }
                                                      </NavLink>
                                                    </TableCell>
                                                  </>
                                                );
                                              }
                                            )}
                                          {obj?.testScore?.length === 1 && (
                                            <>
                                              <TableCell
                                                style={{ width: "14.3%" }}
                                              ></TableCell>
                                            </>
                                          )}
                                          <TableCell
                                            style={{ width: "14.3%" }}
                                          ></TableCell>
                                          {obj?.testScore?.length !== 3 && (
                                            <TableCell
                                              style={{
                                                width: "18%",
                                                textAlign: "right",
                                              }}
                                            >
                                              {remain_days > 0 && (
                                                <Button
                                                  variant="blue"
                                                  className="tRtest_btn"
                                                  onClick={() =>
                                                    navigate(
                                                      `/test-form/${obj?.classMarkFormId}/${obj?._id}`,
                                                      {
                                                        state: {
                                                          orderId:
                                                            dashboardData?._id,
                                                        },
                                                      }
                                                    )
                                                  }
                                                >
                                                  {gothe_mile?.reTest}
                                                </Button>
                                              )}
                                            </TableCell>
                                          )}
                                        </>
                                      )}
                                    </TableRow>
                                  );
                                })
                              ) : (
                                <>
                                  <TableRow>
                                    <TableCell colSpan={7}>
                                      <Typography
                                        sx={{ p: "14px 0", fontWeight: "600" }}
                                      >
                                        {gothe_mile?.noTestInchapter}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </>
                              )}
                            </TableBody>
                          </>
                        ) : (
                          <>
                            <TableBody>
                              <TableRow>
                                <TableCell style={{ width: "4%" }}>
                                  <Typography className="title test_index">
                                    {String.fromCharCode(
                                      id + "a".charCodeAt(0)
                                    ) + ")"}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className="row_data"
                                  style={{ textAlign: "left", width: "8%" }}
                                >
                                  <Typography className="title">
                                    {gothe_mile?.test}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  style={{ textAlign: "left", width: "30%" }}
                                >
                                  <Typography
                                    className="test_name"
                                    sx={{ p: "30px 0" }}
                                  >
                                    {item?.name}
                                  </Typography>
                                </TableCell>
                                {item?.name !== "" && item?.count === 0 ? (
                                  <>
                                    <TableCell
                                      colSpan={3}
                                      style={{ width: "40%" }}
                                    >
                                      <Typography
                                        className="test_name not_test"
                                        style={{ paddingLeft: "20px" }}
                                      >
                                        {remain_days > 0
                                          ? gothe_mile?.dashboard?.noTest
                                          : "you are not able to take test"}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: "18%",
                                        textAlign: "right",
                                      }}
                                    >
                                      {remain_days > 0 && (
                                        <Button
                                          variant="blue"
                                          className="tRtest_btn"
                                          onClick={() =>
                                            navigate(
                                              `/test-form/${item?.classMarkFormId}/${item?._id}`,
                                              {
                                                state: {
                                                  orderId: dashboardData?._id,
                                                },
                                              }
                                            )
                                          }
                                        >
                                          {gothe_mile?.take_test}
                                        </Button>
                                      )}
                                    </TableCell>
                                  </>
                                ) : (
                                  <>
                                    {item?.testScore?.length > 0 &&
                                      item?.testScore?.map((score, index) => {
                                        return (
                                          <>
                                            <TableCell
                                              key={index}
                                              style={{ width: "15%" }}
                                            >
                                              <Button variant="test_outline_btn">
                                                {score?.testPercentage + "%"}
                                              </Button>
                                              <NavLink
                                                to="#"
                                                onClick={() =>
                                                  getTestResultData(item, score)
                                                }
                                                className="shareResult_link"
                                              >
                                                {gothe_mile?.shareResults}
                                              </NavLink>
                                            </TableCell>
                                          </>
                                        );
                                      })}
                                    {item?.testScore?.length === 1 && (
                                      <TableCell
                                        style={{ width: "14.3%" }}
                                      ></TableCell>
                                    )}
                                    <TableCell
                                      style={{ width: "14%" }}
                                    ></TableCell>
                                    {item?.testScore?.length !== 3 && (
                                      <TableCell
                                        style={{
                                          width: "18%",
                                          textAlign: "right",
                                        }}
                                      >
                                        {remain_days > 0 && (
                                          <Button
                                            variant="blue"
                                            className="tRtest_btn"
                                            onClick={() =>
                                              navigate(
                                                `/test-form/${item?.classMarkFormId}/${item?._id}`,
                                                {
                                                  state: {
                                                    orderId: dashboardData?._id,
                                                  },
                                                }
                                              )
                                            }
                                          >
                                            {gothe_mile?.reTest}
                                          </Button>
                                        )}
                                      </TableCell>
                                    )}
                                  </>
                                )}
                              </TableRow>
                            </TableBody>
                          </>
                        )}
                      </>
                    );
                  })
                ) : (
                  <>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={7}>
                          <Typography sx={{ p: "14px 0", fontWeight: "600" }}>
                            {gothe_mile?.noTestInchapter}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </>
                )}
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>

      {/* MOBILE VIEW */}
      <Box className={classes.root_m}>
        {loader ? (
          <PreLoader />
        ) : (
          <Box className="omnibus_details">
            {/* INVOICE & PRICE DETAILS */}
            <Grid
              container
              spacing={2}
              sx={{ px: { xs: "18px", sm: "20px", md: "22px" } }}
            >
              <Grid
                item
                size={{ xs: 6 }}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src={invoice}
                  alt="Invoice"
                  style={{ marginRight: "13px" }}
                />
                <Box>
                  <Typography className="test_detail">
                    {product?.categoryLevel2 &&
                      "$" + product?.categoryLevel2?.price}
                    {product?.categoryLevel3 &&
                      "$" + product?.categoryLevel3?.price}
                    {product?.productForm && "$" + product?.productForm?.price}
                  </Typography>
                  <Link
                    target="_blank"
                    href={`${Config.baseURL}/downloadWithName/${dashboardData?.invoiceUrl}`}
                    className="test_detail dwld_invoice"
                  >
                    {gothe_mile?.dashboard?.download_invoice}
                  </Link>
                </Box>
              </Grid>

              <Grid
                item
                size={{ xs: 6 }}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src={calendarDay}
                  alt="Calander Day Icon"
                  style={{ marginRight: "13px" }}
                />
                <Box>
                  {remain_days > 0 ? (
                    <>
                      <Typography className="test_detail">
                        {remain_days} {gothe_mile?.days}
                      </Typography>
                      <Typography className="test_timedate">
                        {gothe_mile?.dashboard?.time_remaining}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography className="test_detail">
                        subscription expired
                      </Typography>
                      {/* <Typography className="test_detail">expired</Typography> */}
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>

            {/* CHAPTER & TEST ACCORDIAN-DETAILS */}
            <Box
              className={`${!product?.categoryLevel2 ? "test_wrapper" : ""}`}
              sx={{
                borderTop: "1px solid #E7E7E7",
                marginTop: { xs: "18px", sm: "20px", md: "22px" },
              }}
            >
              {subProduct?.length > 0 ? (
                subProduct?.map((item, id) => {
                  return (
                    <>
                      {product?.categoryLevel2 ? (
                        <>
                          {/* CHAPTER ACCORDIAN VIEW */}
                          <Accordion
                            key={id}
                            expanded={expanded === `panel ${id}`}
                            disableGutters
                            elevation={0}
                            onChange={handleChange(`panel ${id}`)}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <Box className="CSummery_block">
                                <Grid
                                  container
                                  spacing={2}
                                  style={{ alignItems: "center" }}
                                >
                                  <Grid item size={{ xs: 0.4 }}>
                                    <Typography className="title test_index">
                                      {id + 1}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    size={{ xs: 2.2, sm: 1.8, md: 1.6 }}
                                  >
                                    <Typography className="title">
                                      {gothe_mile?.dashboard?.chapter}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    size={{ xs: 7.8, sm: 9, md: 9.4 }}
                                    style={{ textAlign: "left" }}
                                  >
                                    <Typography className="test_name">
                                      {item?.name}
                                    </Typography>
                                  </Grid>
                                  <Grid item size={{ xs: 1, sm: 0.6, md: 0.5 }}>
                                    <img
                                      src={up_down_arrow}
                                      alt="arrow"
                                      className="toggle_arrow"
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Box className="Caccordian_details">
                                {item?.subProducts ? (
                                  item?.subProducts?.map((obj, innerid) => {
                                    return (
                                      <>
                                        <Box
                                          sx={{
                                            pb: {
                                              xs: "18px",
                                              sm: "20px",
                                              md: "22px",
                                            },
                                          }}
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item size={{ xs: 0.4 }}>
                                              <Typography className="title test_index">
                                                {String.fromCharCode(
                                                  innerid + "a".charCodeAt(0)
                                                ) + ")"}
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              size={{ xs: 2.4, sm: 1.6 }}
                                            >
                                              <Typography className="title">
                                                {gothe_mile?.test}
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              size={{ xs: 7.4, sm: 9.4 }}
                                              style={{ textAlign: "left" }}
                                            >
                                              <Typography className="test_name">
                                                {obj?.name}
                                              </Typography>
                                            </Grid>
                                          </Grid>

                                          {/* SCORE BLOCK */}
                                          <Grid container spacing={2}>
                                            <Grid item size={{ xs: 12 }}>
                                              <Box className="score_block">
                                                <Grid container spacing={2}>
                                                  {testScore?.map(
                                                    (score, index) => {
                                                      const data =
                                                        obj?.testScore?.[index];
                                                      return (
                                                        <>
                                                          <Grid
                                                            item
                                                            size={{ xs: 4 }}
                                                          >
                                                            <Box
                                                              sx={{
                                                                borderRight:
                                                                  "1px solid #E7E7E7",
                                                                p: {
                                                                  xs: "12px",
                                                                  sm: "16px",
                                                                  md: "18px",
                                                                },
                                                              }}
                                                            >
                                                              <Typography
                                                                className="score_text"
                                                                sx={{
                                                                  pb: "2px",
                                                                }}
                                                              >
                                                                {score?.label}
                                                              </Typography>
                                                              <Typography
                                                                className="score_text"
                                                                sx={{
                                                                  fontWeight:
                                                                    "400!important",
                                                                }}
                                                              >
                                                                {data &&
                                                                  data?.testScore +
                                                                    "/" +
                                                                    data?.testScoreAvailable +
                                                                    " (" +
                                                                    data?.testPercentage +
                                                                    "%)"}
                                                              </Typography>
                                                              {/* <NavLink to="#" onClick={() => getTestResultData(item, score)} className="shareResult_link">{gothe_mile?.shareResults}</NavLink> */}
                                                            </Box>
                                                          </Grid>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </Grid>
                                              </Box>
                                            </Grid>

                                            {obj?.testScore?.length === 0 ? (
                                              <Grid
                                                item
                                                size={{ xs: 12 }}
                                                sx={{
                                                  ml: {
                                                    xs: "30px",
                                                    sm: "40px",
                                                    md: "70px",
                                                  },
                                                }}
                                              >
                                                {remain_days > 0 && (
                                                  <Button
                                                    variant="blue"
                                                    className="tRtest_btn"
                                                    onClick={() =>
                                                      navigate(
                                                        `/test-form/${obj?.classMarkFormId}/${obj?._id}`,
                                                        {
                                                          state: {
                                                            orderId:
                                                              dashboardData?._id,
                                                          },
                                                        }
                                                      )
                                                    }
                                                  >
                                                    {gothe_mile?.take_test}
                                                  </Button>
                                                )}
                                              </Grid>
                                            ) : obj?.testScore?.length !== 3 ? (
                                              <Grid
                                                item
                                                size={{ xs: 12 }}
                                                sx={{
                                                  ml: {
                                                    xs: "30px",
                                                    sm: "40px",
                                                    md: "70px",
                                                  },
                                                }}
                                              >
                                                {remain_days > 0 && (
                                                  <Button
                                                    variant="blue"
                                                    className="tRtest_btn"
                                                    onClick={() =>
                                                      navigate(
                                                        `/test-form/${obj?.classMarkFormId}/${obj?._id}`,
                                                        {
                                                          state: {
                                                            orderId:
                                                              dashboardData?._id,
                                                          },
                                                        }
                                                      )
                                                    }
                                                  >
                                                    {gothe_mile?.reTest}
                                                  </Button>
                                                )}
                                              </Grid>
                                            ) : null}
                                          </Grid>
                                        </Box>
                                      </>
                                    );
                                  })
                                ) : (
                                  <Box
                                    sx={{
                                      pb: {
                                        xs: "18px",
                                        sm: "20px",
                                        md: "22px",
                                      },
                                    }}
                                  >
                                    <Typography
                                      sx={{ p: "14px 0", fontWeight: "600" }}
                                    >
                                      {gothe_mile?.noTestInchapter}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        </>
                      ) : (
                        <>
                          {/* TEST VIEW */}
                          <Box
                            sx={{
                              p: {
                                xs: "18px 12px 0",
                                sm: "20px 14px 0",
                                md: "22px 16px 0",
                              },
                            }}
                          >
                            <Grid
                              container
                              spacing={2}
                              sx={{ alignItems: "center" }}
                            >
                              <Grid item size={{ xs: 0.4 }}>
                                <Typography className="title test_index">
                                  {String.fromCharCode(id + "a".charCodeAt(0)) +
                                    ")"}
                                </Typography>
                              </Grid>
                              <Grid item size={{ xs: 2.4, sm: 1.6 }}>
                                <Typography className="title">
                                  {gothe_mile?.test}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                size={{ xs: 7.4, sm: 9.4 }}
                                style={{ textAlign: "left" }}
                              >
                                <Typography className="test_name">
                                  {item?.name}
                                </Typography>
                              </Grid>
                            </Grid>

                            {/* SCORE BLOCK */}
                            <Grid container spacing={2}>
                              <Grid item size={{ xs: 12 }}>
                                <Box className="score_block">
                                  <Grid container spacing={2}>
                                    {testScore?.map((score, index) => {
                                      const data = item?.testScore?.[index];
                                      return (
                                        <>
                                          <Grid item size={{ xs: 4 }}>
                                            <Box
                                              sx={{
                                                borderRight:
                                                  "1px solid #E7E7E7",
                                                p: {
                                                  xs: "12px",
                                                  sm: "16px",
                                                  md: "18px",
                                                },
                                              }}
                                            >
                                              <Typography
                                                className="score_text"
                                                sx={{ pb: "2px" }}
                                              >
                                                {score?.label}
                                              </Typography>
                                              <Typography
                                                className="score_text"
                                                sx={{
                                                  fontWeight: "400!important",
                                                }}
                                              >
                                                {data &&
                                                  data?.testScore +
                                                    "/" +
                                                    data?.testScoreAvailable +
                                                    " (" +
                                                    data?.testPercentage +
                                                    "%)"}
                                              </Typography>
                                              {/* <NavLink to="#" onClick={() => getTestResultData(item, score)} className="shareResult_link">{gothe_mile?.shareResults}</NavLink> */}
                                            </Box>
                                          </Grid>
                                        </>
                                      );
                                    })}
                                  </Grid>
                                </Box>
                              </Grid>

                              {item?.testScore?.length === 0 ? (
                                <Grid
                                  item
                                  size={{ xs: 12 }}
                                  sx={{
                                    ml: { xs: "30px", sm: "40px", md: "70px" },
                                    textAlign: "center",
                                  }}
                                >
                                  {remain_days > 0 && (
                                    <Button
                                      variant="blue"
                                      className="tRtest_btn"
                                      onClick={() =>
                                        navigate(
                                          `/test-form/${item?.classMarkFormId}/${item?._id}`,
                                          {
                                            state: {
                                              orderId: dashboardData?._id,
                                            },
                                          }
                                        )
                                      }
                                    >
                                      {gothe_mile?.take_test}
                                    </Button>
                                  )}
                                </Grid>
                              ) : item?.testScore?.length !== 3 ? (
                                <Grid
                                  item
                                  size={{ xs: 12 }}
                                  sx={{
                                    ml: { xs: "30px", sm: "40px", md: "70px" },
                                    textAlign: "center",
                                  }}
                                >
                                  {remain_days > 0 && (
                                    <Button
                                      variant="blue"
                                      className="tRtest_btn"
                                      onClick={() =>
                                        navigate(
                                          `/test-form/${item?.classMarkFormId}/${item?._id}`,
                                          {
                                            state: {
                                              orderId: dashboardData?._id,
                                            },
                                          }
                                        )
                                      }
                                    >
                                      {gothe_mile?.reTest}
                                    </Button>
                                  )}
                                </Grid>
                              ) : null}
                            </Grid>
                          </Box>
                        </>
                      )}
                    </>
                  );
                })
              ) : (
                <Box sx={{ textAlign: "center" }}>
                  <Typography sx={{ p: "20px 0 10px", fontWeight: "600" }}>
                    {gothe_mile?.noTestInchapter}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>

      {/* SHARE RESULT POPUP */}
      <TestResultDialogBox
        open={resultPopup}
        handleClose={() => {
          setResultPopup(false);
        }}
        content={<TestResultContent />}
      />
    </>
  );
};

export default Dashboard_details;
