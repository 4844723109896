import React, { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";
import {
  Box,
  Button,
  FormHelperText,
  Typography,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
  Link,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { URLS } from "src/helpers/constants/url";
import { Formik } from "formik";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { Config } from "src/helpers/context/config";
import { gapi } from "gapi-script";
// import { OldSocialLogin as SocialLogin } from "react-social-login";
import { IntlContext } from "src/App";
import { InputTextField } from "../../component/UI/textfield";
import { createStyles, makeStyles } from "@mui/styles";
import axiosInstance from "src/helpers/context/axiosInstance";
import DialogBox from "../../component/UI/DialogBox";
import VerifyOTP from "../OTP/VerifyOTP";
import { saveToStorage } from "src/helpers/context/Storage";
import userDetails from "src/helpers/store/Actions/userDetails";
import { useDispatch } from "react-redux";
// import MuiPhoneNumber from 'material-ui-phone-number';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import PreLoader from "src/component/Loader";
// import { ReactComponent as GoogleIcon } from "src/assets/images/icons/google.svg";
// import { ReactComponent as FacebookIcon } from "src/assets/images/icons/facebook_social_icon.svg";
// import { ReactComponent as AppleIcon } from "src/assets/images/icons/apple-logo.svg";
// import { loginUser } from "src/helpers/store/Actions/auth";
import startsWith from "lodash.startswith";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      // maxWidth: "696px",
      textAlign: "center",
      "& .MuiFormHelperText-root": {
        fontSize: "20px",
        fontWeight: "500",
        lineHeight: "24px",
        color: "#373737",
        letterSpacing: "0px",
        fontFamily: "'Montserrat', sans-serif",
        margin: "0px",
        paddingBottom: "8px",
        "@media (max-width:1199px)": {
          fontSize: "18px",
          lineHeight: "22px",
        },
        "@media (max-width:899px)": {
          fontSize: "16px",
          lineHeight: "20px",
        },
        "@media (max-width:599px)": {
          fontSize: "14px",
          lineHeight: "18px",
        },
        "&.Mui-error": {
          color: "#C01515",
          margin: "4px 14px 0",
          fontSize: "14px",
          lineHeight: "19px",
          padding: "0px",
        },
      },
      // "& .MuiBox-root": {
      //   "@media (max-width:600px)": {
      //     marginBottom: "15px!important",
      //   },
      // },
      "& .max_number": {
        fontSize: "14px",
        lineHeight: "18px",
      },
      "& .MuiOutlinedInput-root": {
        height: "54px",
        padding: "0px",
        lineHeight: "24px",
        paddingRight: "14px",
        "@media (max-width:599px)": {
          height: "45px",
        },
        "& .MuiOutlinedInput-input": {
          "@media (max-width:1199px)": {},
          "@media (max-width:899px)": {
            fontSize: "16px",
            lineHeight: "20px",
          },
          "@media (max-width:599px)": {
            fontSize: "14px",
            lineHeight: "18px",
          },
        },
        "& .MuiSvgIcon-root": {
          fill: "#373737",
          width: "25px",
          height: "25px",

          "@media (max-width:899px)": {
            width: "22px",
            height: "22px",
          },
          "@media (max-width:599px)": {
            width: "20px",
            height: "20px",
          },
        },
      },
      "& .MuiFormControlLabel-root": {
        margin: "0",
        alignItems: "start",
      },
      "& .signup_btn": {
        width: "100%",
        maxWidth: "536px",
        // margin: "56px 0 60px",
        margin: "36px 0 28px",
        "@media (max-width:899px)": {
          height: "60px",
          margin: "30px 0",
        },
        "@media (max-width:599px)": {
          margin: "20px 0",
          height: "48px",
        },
      },
      // "& .MuiTypography-root": {
      //   fontSize: "14px",
      //   lineHeight: "20px",
      // },
      "& .agreement_text": {
        fontSize: "20px",
        lineHeight: "28px",
        color: "#373737",
        "@media (max-width:1199px)": {
          fontSize: "18px",
          lineHeight: "26px",
        },
        "@media (max-width:899px)": {
          fontSize: "16px",
          lineHeight: "24px",
        },
        "@media (max-width:599px)": {
          fontSize: "14px",
          lineHeight: "22px",
        },
      },
      "& .new_signup": {
        fontSize: "20px",
        lineHeight: "24px",
        color: "#707070",
        "@media (max-width:1199px)": {
          fontSize: "18px",
          lineHeight: "22px",
        },
        "@media (max-width:899px)": {
          fontSize: "16px",
          lineHeight: "20px",
        },
        "@media (max-width:599px)": {
          fontSize: "14px",
          lineHeight: "18px",
        },
      },
      "& .signup_link": {
        color: "#405CFB",
        fontSize: "20px",
        lineHeight: "24px",
        fontWeight: "600",
        textDecoration: "underline",
        marginLeft: "5px",
        "@media (max-width:1199px)": {
          fontSize: "18px",
          lineHeight: "22px",
        },
        "@media (max-width:899px)": {
          fontSize: "16px",
          lineHeight: "20px",
        },
        "@media (max-width:599px)": {
          fontSize: "14px",
          lineHeight: "18px",
        },
      },
      "& .phoneNumber": {
        width: "100%",
        "& .MuiInput-root": {
          color: " #707070",
          border: "1px solid #D4D4D4",
          background: "#fff",
          padding: "7px 7px 7px 13px",
          borderRadius: "8px",
          "&:before": {
            borderBottom: "none",
          },
          "&:after": {
            borderBottom: "none",
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
          "&.Mui-disabled": {
            "& .MuiInputAdornment-root": {
              display: "none",
            },
            border: "none",
            background: "#F7F7F7",
          },
          "& .MuiIconButton-root": {
            padding: "0px",
            "& svg": {
              width: "28px",
              height: "28px",
            },
          },
        },
      },
      "& .MuiCheckbox-root": {
        padding: "0 15px 0 0",
        "& svg": {
          fill: "#4AB5FC",
          width: "26px",
          height: "26px",
          "@media (max-width:899px)": {
            width: "20px",
            height: "20px",
          },
        },
      },
      "& .terms_link": {
        color: "#4AB5FC",
        textDecoration: "none",
      },
      "& .social_btn": {
        height: "63px",
        fontSize: "16px",
        fontFamily: "'Lato', sans-serif",
        lineHeight: "19px",
        color: "#101010",
        "@media (max-width:899px)": {
          height: "45px!important",
        },
        "@media (max-width:599px)": {
          height: "40px!important",
          fontSize: "14px",
          padding: "10px",
        },
        "& svg": {
          "& path": {
            transition: "all 0.5s",
          },
        },
        "&:hover": {
          "& path": {
            fill: "#fff",
          },
        },
        "& .flex_align": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    },
    dropdownClass: {
      "& .MuiPaper-root": {
        height: "250px",
        maxHeight: "calc(100% - 200px)",
      },
      "& .MuiMenuItem-root": {
        fontSize: "16px",
      },
    },
  })
);

const SignUp = ({ togglePopup, setTogglePopup, setPopup }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [contactData, setContactData] = useState({});

  const goToLogin = () => {
    setTogglePopup(!togglePopup);
  };

  const closeOtpPopup = () => {
    setOpenPopup(!openPopup);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  // const SocialLoginHandler = async (token, type) => {
  //   // const payload = {
  //   //   accessToken: token,
  //   //   loginType: 'login'
  //   // }
  //   // try {
  //   //   const { data, status } = await axiosInstance.post(URLS.socialLogin + `?type=${type}`, payload);
  //   //   // saveToStorage("authdata", data);
  //   //   // dispatch(loginUser(data));
  //   //   // setPopup(false);
  //   // } catch (error) {
  //   // }
  // };

  // const SocialGoogleLoginHandler = async (token) => {
  //   const payload = {
  //     access_token: token,
  //   };
  //   setLoader(true);
  //   try {
  //     const { data, status } = await axiosInstance.post(
  //       URLS.UserloginGoogle,
  //       payload
  //     );
  //     var authObj = {
  //       ...data,
  //       data: {
  //         ...data?.data,
  //         // name: data?.data?.name,
  //         firstName: data?.data?.name,
  //         lastName: data?.data?.name,
  //       },
  //     };
  //     saveToStorage("authdata", authObj);
  //     dispatch(loginUser(authObj));
  //     if (status === 200 && data?.value === true) {
  //       const payload = {
  //         accessToken: data?.data?.accessToken[0],
  //       };
  //       try {
  //         const { data, status } = await axiosInstance.post(
  //           URLS.userProfile,
  //           payload
  //         );
  //         if (status === 200 && data?.value === true) {
  //           setLoader(false);
  //           // saveToStorage("authdata", data);
  //           // dispatch(loginUser(data));
  //           setPopup(false);
  //         } else {
  //           setLoader(false);
  //         }
  //       } catch (error) {
  //         setLoader(false);
  //       }
  //     } else {
  //       setLoader(false);
  //     }
  //   } catch (error) {
  //     setLoader(false);
  //   }
  // };

  // const SocialFacebookLoginHandler = async (token) => {
  //   const payload = {
  //     access_token: token,
  //   };
  //   setLoader(true);
  //   try {
  //     const { data, status } = await axiosInstance.post(
  //       URLS.UserloginFacebook,
  //       payload
  //     );
  //     if (status === 200 && data?.value === true) {
  //       // const payload = {
  //       //   accessToken: data?.data?.accessToken[0],
  //       // }
  //       // try {
  //       //   const { data, status } = await axiosInstance.post(URLS.userProfile, payload);
  //       //   if (status === 200 && data?.value === true) {
  //       //     setLoader(false);
  //       //     saveToStorage("authdata", data);
  //       //     dispatch(loginUser(data));
  //       //     setPopup(false);
  //       //   } else { setLoader(false); }
  //       // } catch (error) { setLoader(false); }
  //     } else {
  //       setLoader(false);
  //     }
  //   } catch (error) {
  //     setLoader(false);
  //   }
  // };

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: Config?.GoogleAppID,
        scope: "email",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  return (
    <>
      {loader && <PreLoader />}
      <DialogBox
        open={openPopup}
        handleClose={closeOtpPopup}
        title={gothe_mile?.oneTimePassword}
        content={
          <VerifyOTP
            closeOtpPopup={closeOtpPopup}
            setPopup={setPopup}
            setOpenPopup={setOpenPopup}
          />
        }
      />
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: "",
          firstName: "",
          lastName: "",
          email: "",
          mobile: "",
          password: "",
          confirmPassword: "",
          policy: false,
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .max(255)
            .required(gothe_mile?.validation?.required_message),
          lastName: Yup.string()
            .max(255)
            .required(gothe_mile?.validation?.required_message),
          mobile: Yup.string()
            .trim()
            .min(10, "Phone number is not valid")
            // .max(15, "max digit number is not valid")
            .required(gothe_mile?.validation?.required_message),
          email: Yup.string()
            .email(gothe_mile?.validation?.valid_email)
            .max(255)
            .required(gothe_mile?.validation?.required_message),
          password: Yup.string()
            .required(gothe_mile?.validation?.required_message)
            .min(6, gothe_mile?.validation?.passowrd_length),
          // confirmPassword: Yup.string()
          //   .required(gothe_mile?.validation?.required_message)
          //   .when('password', {
          //     is: (val) => (val && val.length > 0 ? true : false),
          //     then: Yup.string().oneOf(
          //       [Yup.ref('password')],
          //       gothe_mile?.validation?.password_confpass_not_match
          //     ),
          //   }),
          confirmPassword: Yup.string()
            .required(gothe_mile?.validation?.required_message)
            .oneOf(
              [Yup.ref("password"), null],
              gothe_mile?.validation?.password_confpass_not_match
            ),
          policy: Yup.boolean().oneOf(
            [true],
            gothe_mile?.validation?.accept_tc
          ),
        })}
        onSubmit={async (data) => {
          const countryCode = contactData?.dialCode;
          const mobileNumber = data?.mobile?.split(`+${countryCode}`).pop();
          let payload = {
            name: data?.firstName + " " + data?.lastName,
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            password: data?.password,
            country_code: countryCode,
            mobile: mobileNumber,
          };

          setLoader(true);
          try {
            const { data, status } = await axiosInstance.post(
              URLS.registerUser,
              payload
            );
            if (status === 200) {
              if (data?.value === true) {
                if (data?.data === "user already exist") {
                  setLoader(false);
                  setError(true);
                } else {
                  setLoader(false);
                  setError(false);
                  setOpenPopup(true);
                  saveToStorage("userDetails", data);
                  dispatch(userDetails(data));
                }
              } else {
                setLoader(false);
              }
            } else {
              setLoader(false);
              setError(data?.error?.message);
            }
          } catch (error) {
            setLoader(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box className={classes.root}>
              <Box style={{ marginBottom: "27px" }}>
                <FormHelperText>{gothe_mile?.firstName}</FormHelperText>
                <InputTextField
                  fullWidth
                  type="text"
                  value={values?.firstName}
                  error={Boolean(touched?.firstName && errors?.firstName)}
                  helperText={touched?.firstName && errors?.firstName}
                  name="firstName"
                  placeholder={gothe_mile?.firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Box>
              <Box style={{ marginBottom: "27px" }}>
                <FormHelperText>{gothe_mile?.lastName}</FormHelperText>
                <InputTextField
                  fullWidth
                  type="text"
                  value={values?.lastName}
                  error={Boolean(touched?.lastName && errors?.lastName)}
                  helperText={touched?.lastName && errors?.lastName}
                  name="lastName"
                  placeholder={gothe_mile?.lastName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Box>
              <Box style={{ marginBottom: "27px" }}>
                <FormHelperText>{gothe_mile?.email_address}</FormHelperText>
                <InputTextField
                  fullWidth
                  name="email"
                  value={values?.email}
                  error={Boolean(touched?.email && errors?.email)}
                  helperText={touched?.email && errors?.email}
                  placeholder={gothe_mile?.email_address}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Box>
              <Box style={{ marginBottom: "27px" }}>
                <FormHelperText className="field_label">
                  {gothe_mile?.phone_number}
                </FormHelperText>
                <PhoneInput
                  // fullWidth
                  // enableLongNumbers={true}
                  // autoFormat={false}
                  country="au"
                  className="phoneNumber"
                  // dropdownClass={classes.dropdownClass}
                  name="mobile"
                  // disableAreaCodes={true}
                  // placeholder={gothe_mile?.phone_number}
                  // error={Boolean(touched?.mobile && errors?.mobile)}
                  // helperText={touched?.mobile && errors?.mobile}
                  isValid={(inputNumber, country, countries) => {
                    return countries.some((country) => {
                      return (
                        startsWith(inputNumber, country.dialCode) ||
                        startsWith(country.dialCode, inputNumber)
                      );
                    });
                  }}
                  value={values?.mobile}
                  onChange={(value, data) => {
                    setFieldValue("mobile", value);
                    setContactData(data);
                  }}
                  onBlur={handleBlur}
                  specialLabel=""
                />
                {Boolean(touched?.mobile && errors?.mobile) && (
                  <Typography className="field-error">
                    {touched?.mobile && errors?.mobile}
                  </Typography>
                )}
              </Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item size={{ xs: 12, sm: 12, md: 6 }}>
                    <FormHelperText className="field_label">
                      {gothe_mile?.password}
                      <span className="max_number">
                        {" "}
                        ({gothe_mile?.max_character})
                      </span>
                    </FormHelperText>
                    <InputTextField
                      fullWidth
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type={showPassword ? "text" : "password"}
                      placeholder={gothe_mile?.password}
                      value={values?.password}
                      error={Boolean(touched?.password && errors?.password)}
                      helperText={touched?.password && errors?.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item size={{ xs: 12, sm: 12, md: 6 }}>
                    <FormHelperText className="field_label">
                      {gothe_mile?.confirm_password}
                    </FormHelperText>
                    <InputTextField
                      fullWidth
                      name="confirmPassword"
                      type={showRepeatPassword ? "text" : "password"}
                      placeholder={gothe_mile?.confirm_password}
                      value={values?.confirmPassword}
                      error={Boolean(
                        touched?.confirmPassword && errors?.confirmPassword
                      )}
                      helperText={
                        touched?.confirmPassword && errors?.confirmPassword
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowRepeatPassword(!showRepeatPassword)
                              }
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showRepeatPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      // onChange={(e) => e.target.value}
                    />
                  </Grid>
                </Grid>
              </Box>
              {error && (
                <Typography sx={{ pt: "20px", color: "#405CFB" }}>
                  {gothe_mile?.message?.register_error}
                </Typography>
              )}
              <Box sx={{ mt: "27px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values?.policy}
                      name="policy"
                      onChange={handleChange}
                    />
                  }
                  label={
                    <Typography
                      sx={{ textAlign: "left" }}
                      className="agreement_text"
                    >
                      {gothe_mile?.agreement_privacy}{" "}
                      <Link
                        className="terms_link"
                        target="_blank"
                        href="/termsconditions"
                      >
                        {gothe_mile?.agreement}
                      </Link>
                      ,{" "}
                      <Link
                        className="terms_link"
                        target="_blank"
                        href="/privacypolicy"
                      >
                        {gothe_mile?.privacyPolicy}
                      </Link>{" "}
                      {gothe_mile?.and}{" "}
                      <Link
                        className="terms_link"
                        target="_blank"
                        href="/termsofuse"
                      >
                        {gothe_mile?.cookiePolicy}
                      </Link>
                    </Typography>
                  }
                />
                {/* < Typography sx={{ textAlign: "left" }}>{gothe_mile?.agrement_privacy}</Typography> */}
              </Box>
              {Boolean(touched.policy && errors.policy) && (
                <FormHelperText style={{ color: "#C01515" }} error>
                  {gothe_mile?.validation?.accept_tc}
                </FormHelperText>
              )}
              <Button
                variant="browse_blue"
                type="submit"
                className="signup_btn"
              >
                {gothe_mile?.signUp}
              </Button>
              {/* <Grid container spacing={2.6} sx={{ mb: "45px" }}>
                <Grid item xs={12} sm={6} className="flex_align">
                  <SocialLogin
                    provider="facebook"
                    scope="email,public_profile"
                    appId={Config?.FacebookAppID}
                    callback={(user) => {
                      if (user) {
                        SocialFacebookLoginHandler(user['_token'].accessToken, 'facebook');
                      }
                    }}
                  >
                    <Button variant="outline_btn" className="social_btn">
                      <Grid container>
                        <Grid item xs={3} className="flex_align">
                          <FacebookIcon />
                        </Grid>
                        <Grid item xs={9} sx={{ textAlign: "left" }}>
                          {gothe_mile?.login_facebook}
                        </Grid>
                      </Grid>
                    </Button>
                  </SocialLogin>
                </Grid>
                <Grid item xs={12} sm={6} className="flex_align">
                  <SocialLogin
                    provider="google"
                    appId={Config?.GoogleAppID}
                    scope="email,profile"
                    callback={(user) => {
                      if (user) {
                        SocialGoogleLoginHandler(user?._token?.accessToken, 'google');
                      }
                    }}
                  >
                    <Button variant="outline_btn" className="social_btn">
                      <Grid container>
                        <Grid item xs={3} className="flex_align">
                          <GoogleIcon />
                        </Grid>
                        <Grid item xs={9} sx={{ textAlign: "left" }}>
                          {gothe_mile?.login_google}
                        </Grid>
                      </Grid>
                    </Button>
                  </SocialLogin>
                </Grid>
                <Button variant="outline_btn" className="social_btn">
                  <Grid container>
                    <Grid item xs={3} className="flex_align">
                      <AppleIcon />
                    </Grid>
                    <Grid item xs={9} sx={{ textAlign: "left" }}>
                      {gothe_mile?.login_apple}
                    </Grid>
                  </Grid>
                </Button>
              </Grid> */}
              <Typography className="new_signup">
                {gothe_mile?.alreadyOnGoExtraMile}
                <NavLink to="#" onClick={goToLogin} className="signup_link">
                  {gothe_mile?.logIn}
                </NavLink>
              </Typography>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default SignUp;
