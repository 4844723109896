import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import { createStyles, makeStyles } from "@mui/styles";
import PreLoader from "src/component/Loader";
import axiosInstance from "src/helpers/context/axiosInstance";
import { URLS } from "src/helpers/constants/url";
import DialogBox from "../../component/UI/DialogBox";
import ChangePassword from "./ChangePassword";
import OtpInput from "react-otp-input";
import { EventEmitter } from "src/helpers/context/EventEmitter";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      textAlign: "center",
      "& .MuiTypography-root": {
        marginBottom: "30px",
        "@media (max-width:899px)": {
          marginBottom: "25px",
        },
      },
      "& .input_box_wraper": {
        width: "200px",
        margin: "0 auto 40px",
        "& .input_box": {
          width: "30px",
          "& .MuiOutlinedInput-root": {
            paddingLeft: "0px",
            border: "0px",
            borderBottom: "1px solid #373737",
            borderRadius: "0px",
            "& .MuiOutlinedInput-input": {
              padding: "0px",
              textAlign: "center",
            },
          },
        },
      },
      "& .MuiButton-root": {
        fontSize: "20px",
        height: "50px",
        maxWidth: "200px",
        width: "100%",
        "@media (max-width:1199px)": {
          fontSize: "18px",
          lineHeight: "16px",
          height: "45px",
        },
        "@media (max-width:899px)": {
          fontSize: "16px",
          lineHeight: "14px",
          padding: "12px",
          height: "40px",
        },
        "@media (max-width:599px)": {
          fontSize: "13px",
          height: "35px",
          lineHeight: "16px",
        },
      },
      "& .resend_otp": {
        color: "#405CFB",
        fontSize: "20px",
        lineHeight: "24px",
        fontWeight: "500",
        textTransform: "capitalize",
        padding: "0px",
        height: "30px",
        "&:hover": {
          backgroundColor: "#fff",
        },
        "@media (max-width:599px)": {
          fontSize: "14px",
        },
      },
    },
  })
);

const VerifyOTP = ({ userId, userEmail, closeOtpPopup }) => {
  const classes = useStyles();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const [loader, setLoader] = useState(false);
  const [changePasswordPopup, setChangePasswordPopup] = useState(false);
  const [error, setError] = useState("");
  const [resendOTP, setResendOTP] = useState(false);

  const closePopup = () => {
    setChangePasswordPopup(false);
  };

  const resendOtp = async () => {
    let payload = {
      email: userEmail,
    };
    setLoader(true);
    try {
      const { data, status } = await axiosInstance.post(
        URLS?.forgotPassword,
        payload
      );
      if (status === 200 && data?.value === true) {
        setError("");
        setLoader(false);
        setResendOTP(true);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    EventEmitter.subscribe("processafterchangepassword", (event) => {
      setChangePasswordPopup(false);
    });
  }, []);

  return (
    <>
      {loader && <PreLoader />}
      <DialogBox
        open={changePasswordPopup}
        handleClose={closePopup}
        title={gothe_mile?.change_password}
        content={<ChangePassword userId={userId} closePopup={closePopup} />}
      />
      <Box className={classes.root}>
        <Typography variant="h4">
          {gothe_mile?.password_sent}
          {userEmail && (
            <span style={{ color: "#405CFB" }}>{" " + userEmail}</span>
          )}
        </Typography>
        {/* <Typography>{gothe_mile?.enter_Otp}</Typography> */}
        <Typography sx={{ fontSize: "22px" }}>
          {gothe_mile?.pleaseEnter}{" "}
          {error && (
            <span style={{ color: "#405CFB" }}>{gothe_mile?.right}</span>
          )}{" "}
          {gothe_mile?.oneTimePasswordCapitalize}
        </Typography>
        <Formik
          initialValues={{
            first: "",
            second: "",
            third: "",
            fourth: "",
          }}
          validationSchema={Yup.object().shape({
            first: Yup.string()
              .required("Please Enter OTP")
              .min(4, "Must be 4 Digits required"),
          })}
          onSubmit={async (data) => {
            let payload = {
              first: data?.first?.split("")?.[0],
              second: data?.first?.split("")?.[1],
              third: data?.first?.split("")?.[2],
              fourth: data?.first?.split("")?.[3],
              verifyOtp: data?.first,
              _id: userId,
            };
            setLoader(true);
            setResendOTP(false);
            try {
              const { data, status } = await axiosInstance.post(
                URLS.confirmForgotPasswordOtp,
                payload
              );
              if (status === 200) {
                if (data?.value === true) {
                  setLoader(false);
                  setChangePasswordPopup(true);
                } else {
                  setLoader(false);
                  setError(data);
                }
              } else {
                setLoader(false);
              }
            } catch (error) {
              setLoader(false);
            }
          }}
        >
          {({ errors, handleSubmit, values, touched, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Box className="input_box_wraper">
                <Box className="input_box_otp_wrapper">
                  <OtpInput
                    name="first"
                    className="input-box-otp"
                    value={values?.first}
                    onChange={(e) => setFieldValue("first", e)}
                    numInputs={4}
                    isInputNum
                    containerStyle="input_box_otp_wrapper"
                    renderInput={(props) => <input {...props} />}
                  />
                </Box>
                <Box mt={2}>
                  <span className="text-danger">
                    {touched?.first && errors?.first}
                  </span>
                </Box>
              </Box>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                sx={{ justifyContent: "center" }}
              >
                <Grid item size={{ xs: 5 }}>
                  <Button type="submit" variant="browse_blue">
                    {gothe_mile?.verify_otp}
                  </Button>
                </Grid>
                <Grid item size={{ xs: 5 }}>
                  <Button variant="outline_btn" onClick={closeOtpPopup}>
                    {gothe_mile?.cancel}
                  </Button>
                </Grid>
              </Grid>
              <Box sx={{ mt: "30px" }}>
                <Button className="resend_otp" onClick={resendOtp}>
                  {gothe_mile?.resend_otp}
                </Button>
              </Box>
              {resendOTP && (
                <Typography
                  sx={{
                    color: "#248F17",
                    m: "20px 0 0 0!important",
                    fontSize: "16px",
                  }}
                >
                  {gothe_mile?.message?.requestedNewOTP}
                </Typography>
              )}
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
};
export default VerifyOTP;
