import React from "react";
import { Box, Typography } from "@mui/material";
import "./ui.scss";

const OmnibusBanner = ({ title, content, xs_col, col, color }) => {
  return (
    <>
      <Box>
        <Typography
          variant="subtitle1"
          sx={{ color: color, textAlign: { xs: "center", md: "left" } }}
        >
          {title}
        </Typography>
        {content}
      </Box>
    </>
  );
};
export default OmnibusBanner;
