import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import moment from "moment";
import { IntlContext } from "src/App";
import { URLS } from "src/helpers/constants/url";
import axiosInstance from "src/helpers/context/axiosInstance";
import { NavLink } from "react-router-dom";
import { ReactComponent as Cart } from "src/assets/images/icons/accordianCart.svg";
import CartDialogBox from "../UI/CartDialogBox";
import { ReactComponent as Rarrow } from "src/assets/images/icons/arrow-right.svg";
import { ReactComponent as ACheckmark } from "src/assets/images/icons/acheckmark.svg";

const Category_summery = ({
  auth,
  categoryLevel2,
  loginPopup,
  cartItem,
  cartChapterItem,
  setCartChapterItem,
  subscriptionPackage,
  purchaseOmnibusArray,
  purchaseChapterArray,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const [loader, setLoader] = useState(false);
  const [cartPopup, setCartPopup] = useState(false);

  const catlevel3 = location?.pathname?.split("/")[2];

  const getCartItem = async () => {
    let payload = {
      orderId: null,
      productForm: [],
      user: auth?.data?._id,
    };
    var CurrentDate = moment().format("YYYY-MM-DD  HH:mm:ss ZZ");
    var NextDate = moment().add(1, "M");
    var ExpireDate = moment(NextDate).format("YYYY-MM-DD  HH:mm:ss ZZ");
    let catData = {
      categoryLevel3: { ...categoryLevel2 },
      packageName: [subscriptionPackage?.name, catlevel3, categoryLevel2?.name],
      orderDate: CurrentDate,
      expiryDate: ExpireDate,
    };
    setLoader(true);
    try {
      const { data, status } = await axiosInstance.post(
        URLS.getCartItem,
        payload
      );
      if (status === 200 && data?.value === true) {
        setLoader(false);
        setLoader(false);
        data?.data?.productForm?.push(catData);
        addCartItemData(data?.data);
      } else if (status === 200 && data?.value === false) {
        getCartItem();
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const addCartItemData = async (payload) => {
    try {
      const { status } = await axiosInstance.post(URLS.addCartItem, payload);
      if (status === 200) {
        let item = payload?.productForm?.map((obj) => {
          return obj?.categoryLevel3?._id;
        });
        setCartChapterItem(item);
        openPopup();
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const openPopup = () => {
    setCartPopup(true);
  };

  const AddToCartContent = () => {
    return (
      <>
        <Box>
          <Box
            className="flex_content checkmarkIcon"
            sx={{ justifyContent: "flex-start" }}
          >
            <ACheckmark />
            <Typography className="addcart_text" sx={{ ml: "25px" }}>
              {gothe_mile?.cart?.testAdded}
            </Typography>
          </Box>
          <Typography
            className="addcart_title"
            sx={{ textTransform: "uppercase" }}
          >
            {subscriptionPackage?.name + "-" + catlevel3}
            <span style={{ textTransform: "capitalize" }}>
              {"-" + categoryLevel2?.name}
            </span>
          </Typography>
          <Typography variant="subtitle2" className="test_cost">
            {categoryLevel2?.price && "Price: $" + categoryLevel2?.price}
          </Typography>
          <Grid container spacing={{ xs: 2, ms: 3 }}>
            <Grid item size={{ xs: 12, md: 6 }}>
              <Button
                variant="checkout_btn"
                // href="/cart"
                onClick={() => {
                  setCartPopup(false);
                  setCartChapterItem([]);
                  navigate("/cart");
                }}
              >
                {gothe_mile?.cart?.cart_checkout}
              </Button>
            </Grid>
            <Grid item size={{ xs: 12, md: 6 }}>
              <Button variant="outline_btn" onClick={() => setCartPopup(false)}>
                {gothe_mile?.cart?.continueShopping}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  return (
    <>
      <Box>
        <Box className="flex_content">
          <Typography className="catLevel_name">
            {categoryLevel2?.name}
          </Typography>
          {purchaseOmnibusArray?.includes(subscriptionPackage?._id) ||
          purchaseChapterArray?.includes(categoryLevel2?._id) ? (
            <NavLink to="/dashboard" className="flex_content linkDeco">
              <Typography className="add_chapter">
                {gothe_mile?.view_package}
              </Typography>
              <Rarrow className="r-arrow" />
            </NavLink>
          ) : cartItem?.includes(subscriptionPackage?._id) ||
            cartChapterItem?.includes(categoryLevel2?._id) ? (
            <Box className="price_chapter_details">
              <NavLink to="/cart" className="flex_content linkDeco">
                <Typography className="add_chapter_price">
                  {categoryLevel2?.price && "AU $" + categoryLevel2?.price}
                </Typography>
                <Typography sx={{ mr: "10px" }} className="add_chapter">
                  {gothe_mile?.alreadyIncart}
                </Typography>
                <Rarrow className="r-arrow" />
              </NavLink>
            </Box>
          ) : (
            <>
              <Box className="price_chapter_details">
                <Typography className="add_chapter_price">
                  {categoryLevel2?.price && "AU $" + categoryLevel2?.price}
                </Typography>
                <NavLink
                  to="#"
                  className="flex_content linkDeco"
                  onClick={() => (auth ? getCartItem() : loginPopup())}
                >
                  <Cart className="cartIcon" />

                  <Typography sx={{ ml: "10px" }} className="add_chapter">
                    {gothe_mile?.addChapter_to_cart}
                  </Typography>
                </NavLink>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <CartDialogBox
        open={cartPopup}
        handleClose={() => setCartPopup(false)}
        content={<AddToCartContent />}
      />
    </>
  );
};
export default Category_summery;
