import React, { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  FormHelperText,
  Typography,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { IntlContext } from "src/App";
import PreLoader from "src/component/Loader";
import { Config } from "src/helpers/context/config";
import { gapi } from "gapi-script";
// import { OldSocialLogin as SocialLogin } from "react-social-login";
import { URLS } from "src/helpers/constants/url";
import { InputTextField } from "src/view/component/UI/textfield";
import { createStyles, makeStyles } from "@mui/styles";
import axiosInstance from "src/helpers/context/axiosInstance";
import { saveToStorage } from "src/helpers/context/Storage";
import { loginUser } from "src/helpers/store/Actions/auth";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import { getCookie } from "src/helpers/store/Cookies";
// import { ReactComponent as GoogleIcon } from "src/assets/images/icons/google.svg";
// import { ReactComponent as FacebookIcon } from "src/assets/images/icons/facebook_social_icon.svg";
// import { ReactComponent as AppleIcon } from "src/assets/images/icons/apple-logo.svg";
import DialogBox from "src/view/component/UI/DialogBox";
import VerifyOTP from "../OTP/VerifyOTP";
import { EventEmitter } from "src/helpers/context/EventEmitter";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      textAlign: "center",
      "& .flex_content": {
        "@media (max-width:375px)": {
          flexDirection: "column",
        },
        "& .MuiFormControlLabel-root": {
          margin: "0",
          "@media (max-width:375px)": {
            marginRight: "auto",
            marginBottom: "15px",
          },
        },
      },
      "@media (max-width:600px)": {
        paddingTop: "0px",
      },
      "& .MuiFormHelperText-root": {
        fontSize: "20px",
        fontWeight: "500",
        lineHeight: "24px",
        color: "#373737",
        letterSpacing: "0px",
        fontFamily: "'Montserrat', sans-serif",
        margin: "0px",
        paddingBottom: "8px",
        "@media (max-width:1199px)": {
          fontSize: "18px",
          lineHeight: "22px",
        },
        "@media (max-width:899px)": {
          fontSize: "16px",
          lineHeight: "20px",
        },
        "@media (max-width:599px)": {
          fontSize: "14px",
          lineHeight: "18px",
        },
      },
      "& .MuiOutlinedInput-root": {
        height: "54px",
        padding: "0px",
        lineHeight: "24px",
        paddingRight: "14px",
        "@media (max-width:599px)": {
          height: "45px",
        },
        "& .MuiOutlinedInput-input": {
          "@media (max-width:1199px)": {},
          "@media (max-width:899px)": {
            fontSize: "16px",
            lineHeight: "20px",
          },
          "@media (max-width:599px)": {
            fontSize: "14px",
            lineHeight: "18px",
          },
        },
        "& .MuiIconButton-root": {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        "& .MuiSvgIcon-root": {
          fill: "#373737",
          width: "36px",
          height: "36px",
          "@media (max-width:1199px)": {
            width: "30px",
            height: "30px",
          },
          "@media (max-width:899px)": {
            width: "25px",
            height: "25px",
          },
          "@media (max-width:599px)": {
            width: "20px",
            height: "20px",
          },
        },
      },
      "& .MuiCheckbox-root": {
        padding: "0 14px 0 0",
        "& svg": {
          fill: "#4AB5FC",
          width: "26px",
          height: "26px",
          "@media (max-width:899px)": {
            width: "20px",
            height: "20px",
          },
        },
      },
      "& .login_btn": {
        width: "100%",
        maxWidth: "536px",
        // margin: "56px 0 60px",
        margin: "36px 0 28px",
        "@media (max-width:899px)": {
          height: "60px",
          margin: "30px 0",
        },
        "@media (max-width:599px)": {
          margin: "20px 0",
          height: "48px",
        },
      },
      "& .social_btn": {
        height: "63px",
        fontSize: "16px",
        fontFamily: "'Lato', sans-serif",
        lineHeight: "19px",
        color: "#101010",
        "@media (max-width:899px)": {
          height: "45px!important",
        },
        "@media (max-width:599px)": {
          height: "40px!important",
          fontSize: "14px",
          padding: "10px",
        },
        "& svg": {
          "& path": {
            transition: "all 0.5s",
          },
        },
        "&:hover": {
          "& path": {
            fill: "#fff",
          },
        },
        "& .flex_align": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
      // "& .MuiTypography-root": {
      //   lineHeight: "24px",
      // },
      "& .rememberMe_text": {
        fontSize: "20px",
        lineHeight: "28px",
        color: "#373737",
        "@media (max-width:1199px)": {
          fontSize: "18px",
          lineHeight: "26px",
        },
        "@media (max-width:899px)": {
          fontSize: "16px",
          lineHeight: "24px",
        },
        "@media (max-width:599px)": {
          fontSize: "14px",
          lineHeight: "22px",
        },
      },
      "& .new_signup": {
        color: "#707070",
        fontSize: "20px",
        lineHeight: "24px",
        "@media (max-width:1199px)": {
          fontSize: "18px",
          lineHeight: "22px",
        },
        "@media (max-width:899px)": {
          fontSize: "16px",
          lineHeight: "20px",
        },
        "@media (max-width:599px)": {
          fontSize: "14px",
          lineHeight: "18px",
        },
      },
      "& .forgot_navlink": {
        color: "#405CFB",
        fontSize: "20px",
        lineHeight: "24px",
        fontWeight: "500",
        "@media (max-width:1199px)": {
          fontSize: "18px",
          lineHeight: "22px",
        },
        "@media (max-width:899px)": {
          fontSize: "16px",
          lineHeight: "20px",
        },
        "@media (max-width:599px)": {
          fontSize: "14px",
          lineHeight: "18px",
        },
      },
      "& .signup_link": {
        fontWeight: "600",
        textDecoration: "underline",
        marginLeft: "5px",
      },
    },
  })
);

const LogIn = ({ togglePopup, setTogglePopup, setPopup }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const [showPassword, setShowPassword] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openOtpPopup, setOpenOtpPopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isCheckBox, setIsCheckBox] = useState(false);
  const [error, setError] = useState("");

  const goToSignup = () => {
    setTogglePopup(!togglePopup);
  };

  const closePasswordPopup = () => {
    setOpenPopup(!openPopup);
  };
  const closeOtpPopup = () => {
    setOpenOtpPopup(!openOtpPopup);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const getEmail = getCookie("email");
  const getPassword = getCookie("Password");

  // const SocialGoogleLoginHandler = async (token) => {
  //   const payload = {
  //     access_token: token,
  //   };
  //   setLoader(true);
  //   try {
  //     const { data, status } = await axiosInstance.post(
  //       URLS.UserloginGoogle,
  //       payload
  //     );
  //     var authObj = {
  //       ...data,
  //       data: {
  //         ...data?.data,
  //         firstName: data?.data?.name,
  //         lastName: data?.data?.name,
  //       },
  //     };
  //     saveToStorage("authdata", authObj);
  //     dispatch(loginUser(authObj));
  //     if (status === 200 && data?.value === true) {
  //       const payload = {
  //         accessToken: data?.data?.accessToken[0],
  //       };
  //       try {
  //         const { data, status } = await axiosInstance.post(
  //           URLS.userProfile,
  //           payload
  //         );
  //         if (status === 200 && data?.value === true) {
  //           setLoader(false);
  //           // saveToStorage("authdata", data);
  //           // dispatch(loginUser(data));
  //           setPopup(false);
  //         } else {
  //           setLoader(false);
  //         }
  //       } catch (error) {
  //         setLoader(false);
  //       }
  //     } else {
  //       setLoader(false);
  //     }
  //   } catch (error) {
  //     setLoader(false);
  //   }
  // };

  // const SocialFacebookLoginHandler = async (token) => {
  //   const payload = {
  //     access_token: token,
  //   };
  //   setLoader(true);
  //   try {
  //     const { data, status } = await axiosInstance.post(
  //       URLS.UserloginFacebook,
  //       payload
  //     );
  //     if (status === 200 && data?.value === true) {
  //       // const payload = {
  //       //   accessToken: data?.data?.accessToken[0],
  //       // }
  //       // try {
  //       //   const { data, status } = await axiosInstance.post(URLS.userProfile, payload);
  //       //   if (status === 200 && data?.value === true) {
  //       //     setLoader(false);
  //       //     saveToStorage("authdata", data);
  //       //     dispatch(loginUser(data));
  //       //     setPopup(false);
  //       //   } else { setLoader(false); }
  //       // } catch (error) { setLoader(false); }
  //     } else {
  //       setLoader(false);
  //     }
  //   } catch (error) {
  //     setLoader(false);
  //   }
  // };

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: Config?.GoogleAppID,
        scope: "email",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  useEffect(() => {
    var checkVal = getEmail === "" && getPassword === "" ? false : true;
    setIsCheckBox(checkVal);
  }, [getEmail, getPassword]);

  useEffect(() => {
    EventEmitter.subscribe("processafterchangepassword", (event) => {
      setOpenPopup(false);
    });
  }, []);

  return (
    <>
      {loader && <PreLoader />}
      <DialogBox
        open={openPopup}
        handleClose={closePasswordPopup}
        title={gothe_mile?.forgot_password}
        content={<ForgotPassword closePasswordPopup={closePasswordPopup} />}
      />
      <DialogBox
        open={openOtpPopup}
        handleClose={closeOtpPopup}
        title={gothe_mile?.oneTimePassword}
        content={
          <VerifyOTP
            closeOtpPopup={closeOtpPopup}
            setPopup={setPopup}
            setOpenOtpPopup={setOpenOtpPopup}
          />
        }
      />
      <Formik
        enableReinitialize={true}
        initialValues={{
          email: document.cookie ? `${getEmail}` : "",
          password: document.cookie ? `${getPassword}` : "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(gothe_mile?.validation?.valid_email)
            .max(255)
            .required(gothe_mile?.validation?.required_message),
          password: Yup.string().required(
            gothe_mile?.validation?.required_message
          ),
          // .min(6, gothe_mile?.validation?.passowrd_length),
        })}
        onSubmit={async (data) => {
          let payload = {
            email: data?.email,
            password: data?.password,
          };
          setLoader(true);
          try {
            const { data, status } = await axiosInstance.post(
              URLS.loginUser,
              payload
            );
            if (status === 200) {
              if (data?.value === true) {
                if (data?.data?.verifyAcc === true) {
                  setLoader(false);
                  saveToStorage("authdata", data);
                  dispatch(loginUser(data));
                  setPopup(false);
                  if (isCheckBox === true) {
                    document.cookie = `email=${data?.data?.email}`;
                    document.cookie = `Password=${payload?.password}`;
                  }
                } else {
                  setLoader(false);
                  setOpenOtpPopup(true);
                }
              } else {
                setLoader(false);
                setError(data?.error?.message);
              }
            } else {
              setLoader(false);
            }
          } catch (error) {
            setLoader(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box className={classes.root}>
              <Box style={{ marginBottom: "27px" }}>
                <FormHelperText>{gothe_mile?.email_address}</FormHelperText>
                <InputTextField
                  fullWidth
                  type="email"
                  name="email"
                  value={values?.email}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={gothe_mile?.email_address}
                />
              </Box>
              <Box>
                <FormHelperText>{gothe_mile?.password}</FormHelperText>
                <InputTextField
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={values?.password}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={gothe_mile?.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              {error && (
                <Typography sx={{ pt: "20px", color: "#C01515" }}>
                  {gothe_mile?.message?.login_error}
                </Typography>
              )}
              <Box className="flex_content" style={{ marginTop: "27px" }}>
                <FormControlLabel
                  // control={<Checkbox checked={document.cookie && setIsCheckBox(true)} />}
                  control={<Checkbox />}
                  label={
                    <Typography className="rememberMe_text">
                      {gothe_mile?.remember_me}
                    </Typography>
                  }
                  checked={isCheckBox}
                  onChange={() => setIsCheckBox(!isCheckBox)}
                />
                <NavLink
                  to="#"
                  className="forgot_navlink"
                  onClick={() => setOpenPopup(true)}
                >
                  {gothe_mile?.forgot_password}
                </NavLink>
              </Box>
              <Button type="submit" variant="browse_blue" className="login_btn">
                {gothe_mile?.logIn}
              </Button>
              <Typography className="new_signup">
                {gothe_mile?.newToGoExtraMile}
                <NavLink
                  to="#"
                  onClick={goToSignup}
                  className="forgot_navlink signup_link"
                >
                  {gothe_mile?.signUp}
                </NavLink>
              </Typography>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default LogIn;
