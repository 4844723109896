import { React, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  Link,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { IntlContext } from "src/App";
import "./footer.scss";
import { NavLink } from "react-router-dom";
import { createStyles, makeStyles } from "@mui/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import AppLogo from "src/component/Logo";
import { InputTextField } from "src/view/component/UI/textfield";
import axiosInstance from "src/helpers/context/axiosInstance";
import { URLS } from "src/helpers/constants/url";
import MessageBox from "src/view/component/UI/MessageBox";
import PreLoader from "src/component/Loader";
import { identifiers } from "src/helpers/constants/identifier";
import { HashLink } from "react-router-hash-link";
import gotoAppLogo from "src/assets/images/gotoApp.jpg";
import { getCookie } from "src/helpers/store/Cookies";
import Safari from "src/assets/images/icons/Safari.svg";
import Chrome from "src/assets/images/icons/Google_Chrome.svg";
import Firefox from "src/assets/images/icons/Firefox.svg";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: "relative",
      "& .MuiOutlinedInput-root": {
        height: "54px",
        paddingLeft: "0",
        "& .MuiOutlinedInput-input": {
          padding: "18px 21px 17px",
          height: "18px",
          "@media (max-width:1199px)": {
            padding: "14px 18px",
          },
          "@media (max-width:899px)": {
            height: "16px",
            padding: "12px 14px",
          },
          "@media (max-width:599px)": {
            padding: "12px",
            height: "14px",
          },
        },
        "@media (max-width:1199px)": {
          height: "48px",
        },
        "@media (max-width:899px)": {
          height: "42px",
        },
        "@media (max-width:599px)": {
          height: "38px",
        },
      },
      "& .email_link": {
        color: "#405CFB",
        textDecoration: "none",
      },
      "& .overlay": {
        backgroundColor: " rgba(0,0,0,0.5)",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: "999",
        display: "none",
        "@media (max-width:1024px)": {
          display: "block",
        },
        "& .link_top_block": {
          background: "#fff",
          position: "absolute",
          padding: "11px 20px",
          // margin: "0 -30px",
          top: 0,
          left: 0,
          right: 0,
          "& .heading": {
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "20px",
            "@media (max-width:899px)": {
              fontSize: "14px",
              lineHeight: "18px",
            },
            "@media (max-width:599px)": {
              fontSize: "12px",
              lineHeight: "16px",
            },
          },
          "& .subheading": {
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "18px",
            color: " #8A8A8D",
            "@media (max-width:899px)": {
              fontSize: "12px",
              lineHeight: "16px",
            },
            "@media (max-width:599px)": {
              fontSize: "10px",
              lineHeight: "14px",
            },
          },
          "& .openAppBtn": {
            width: "100%",
            maxWidth: " 94px",
            backgroundColor: "#4587FB",
            color: "#fff",
            borderRadius: "40px",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "19px",
          },
        },
        "& .link_bottom_block": {
          background: "#fff",
          position: "absolute",
          padding: "11px 20px 30px",
          // margin: "0 -30px",
          bottom: 0,
          left: 0,
          right: 0,
          borderRadius: "15px 15px 0 0",
          "& .title": {
            fontSize: "17px",
            fontWeight: "700",
            lineHeight: "22px",
            textAlign: "center",
            paddingBottom: "20px",
          },
          "& .gotoAppTitle": {
            fontSize: "14px",
            fontWeight: "700",
            lineHeight: "19px",
          },
          "& .browserlogo": {
            width: "47px",
            height: "47px",
            display: "flex",
            alignItems: "center",
            border: "1px solid #edeff1",
            borderRadius: "5px",
            padding: "5px",
            marginRight: "14px",
          },
          "& .openAppBtn": {
            width: "100%",
            maxWidth: " 94px",
            backgroundColor: "#4587FB",
            color: "#fff",
            borderRadius: "40px",
            fontSize: "14px",
            fontWeight: "700",
            textTransform: "capitalize",
          },
          "& .continueBtn": {
            color: "#787c7e",
            backgroundColor: "#f6f7f8",
            border: "1px solid #d3d6da",
          },
        },
      },
    },
  })
);

const Footer = () => {
  const classes = useStyles();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const [messageBox, setMessageBox] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const [userPlateformSlection, setUserPlateformSlection] = useState(true);
  const [appUrl, setAppUrl] = useState(
    "https://apps.apple.com/in/app/critical-care-mcq/id1576829548"
  );
  const [browserName, setBrowserName] = useState("");
  const [browserLogo, setBrowserLogo] = useState(Chrome);

  const stayInBrowser = () => {
    setUserPlateformSlection(false);
    document.cookie = `browser=${true}`;
  };
  const defaultSetInBrowser = getCookie("browser");
  const browser_type = navigator.userAgent;

  useEffect(() => {
    if (defaultSetInBrowser === "true") {
      setUserPlateformSlection(false);
    }
    if (browser_type.match(/android/i)) {
      setAppUrl(
        "https://play.google.com/store/apps/details?id=com.gtem.app&hl=en&gl=US"
      );
    }
    if (browser_type.match(/chrome|chromium|crios/i)) {
      setBrowserName("Chrome");
      setBrowserLogo(Chrome);
    } else if (browser_type.match(/firefox|fxios/i)) {
      setBrowserName("Firefox");
      setBrowserLogo(Firefox);
    } else if (browser_type.match(/safari/i)) {
      setBrowserName("Safari");
      setBrowserLogo(Safari);
    } else {
      setBrowserName("No browser detection");
      setBrowserLogo(Chrome);
    }
  }, []);

  return (
    <>
      {loader && <PreLoader />}
      <MessageBox
        open={messageBox}
        handleClose={() => setMessageBox(false)}
        content={
          error ? (
            <Typography className="text-danger">{error}</Typography>
          ) : (
            <Typography>{gothe_mile?.message?.thank_u_signingup}</Typography>
          )
        }
      />
      <Box className={classes.root}>
        <Grid container spacing={3} className="flex_content footer_content">
          <Grid item size={{ md: 12, lg: 3.6 }}>
            <NavLink to="/">
              <AppLogo />
            </NavLink>
            <p className="email_data">
              {gothe_mile?.emailus} <br />
              <Link
                className="email_link"
                href="mailto:support@gotheextramile.com"
              >
                {gothe_mile?.supportMail}
              </Link>
            </p>
            <p className="payment_notice">{gothe_mile?.payment?.instruction}</p>
            <Box className="payment_card_section">
              {identifiers?.payment_card?.map((card, id) => {
                return (
                  <>
                    <Box key={id} className="payment_card">
                      {card?.type}
                    </Box>
                  </>
                );
              })}
            </Box>
          </Grid>
          <Grid item size={{ md: 12, lg: 2 }} className="footer_link_section">
            {identifiers?.footer_menu?.map((menu, id) => {
              return (
                <HashLink
                  key={id}
                  className="footer_navlink"
                  smooth
                  to={menu?.href}
                >
                  {menu?.name}
                </HashLink>
              );
            })}
          </Grid>
          <Grid item size={{ xs: 12, lg: 4.252 }}>
            <Typography variant="h4" className="sign-up_title">
              {gothe_mile?.newsignup}
            </Typography>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .required(gothe_mile?.validation?.enterEmailId)
                  .email(gothe_mile?.validation?.valid_email),
              })}
              onSubmit={async (data, { resetForm }) => {
                let payload = {
                  email: data?.email,
                };
                setLoader(true);
                try {
                  const { data, status } = await axiosInstance.post(
                    URLS?.newsLetterSave,
                    payload
                  );
                  if (status === 200) {
                    resetForm();
                    if (data?.value === true) {
                      setLoader(false);
                      setMessageBox(true);
                      setError("");
                    } else {
                      setLoader(false);
                      setMessageBox(true);
                      setError(data?.error?._message);
                    }
                  } else {
                    setLoader(false);
                    resetForm();
                  }
                } catch (error) {
                  setLoader(false);
                  setMessageBox(true);
                  setError(error?.response?.data?.message);
                }
              }}
            >
              {({
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <InputTextField
                    fullWidth
                    type="email"
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    variant="outlined"
                    size="small"
                    placeholder={gothe_mile?.enterYourEmaiId}
                    name="email"
                    value={values?.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Button type="submit" variant="blue" className="sub_btn">
                    {gothe_mile?.subscribe}
                  </Button>
                </form>
              )}
            </Formik>
            <Box className="social_links">
              {identifiers?.social_links?.map((link, id) => {
                return (
                  <>
                    <IconButton
                      key={id}
                      target="_blank"
                      href={link?.href}
                      className="social_link"
                    >
                      {link?.icon}
                    </IconButton>
                  </>
                );
              })}
            </Box>
          </Grid>
        </Grid>
        <Typography variant="h6" className="copyright_content">
          {gothe_mile?.copyrights}
        </Typography>

        {userPlateformSlection && (
          <Box className="overlay">
            <Box className="link_top_block">
              <Grid container sx={{ alignItems: "center" }}>
                <Grid item size={{ xs: 8.5, sm: 6, md: 6 }}>
                  <Grid container sx={{ alignItems: "center" }}>
                    <Grid item size={{ xs: 2.5, sm: 2, md: 1.5 }}>
                      <Box sx={{ mr: "10px" }}>
                        <img src={gotoAppLogo} alt="logo" />
                      </Box>
                    </Grid>
                    <Grid item size={{ xs: 9.5, sm: 10, md: 10 }}>
                      <Typography className="heading">
                        {gothe_mile?.linkToApp?.appName}
                      </Typography>
                      <Typography className="subheading">
                        {gothe_mile?.linkToApp?.openInApp}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  size={{ xs: 3.5, sm: 6, md: 6 }}
                  sx={{ textAlign: "right" }}
                >
                  <Button className="openAppBtn" target="_blank" href={appUrl}>
                    {gothe_mile?.linkToApp?.open}
                  </Button>
                </Grid>
              </Grid>
            </Box>

            <Box className="link_bottom_block">
              <Typography className="title">
                {gothe_mile?.linkToApp?.title}
              </Typography>
              <Divider />
              <Box sx={{ p: "16px 0" }}>
                <Grid container sx={{ alignItems: "center" }}>
                  <Grid item size={{ xs: 8, sm: 8, md: 8 }}>
                    <Grid container sx={{ alignItems: "center" }}>
                      <Grid item size={{ xs: 3, sm: 2.5, md: 1.5 }}>
                        <Box className="browserlogo">
                          <img src={gotoAppLogo} alt="App logo" />
                        </Box>
                      </Grid>
                      <Grid item size={{ xs: 9, sm: 8.5, md: 8.5 }}>
                        <Typography className="gotoAppTitle">
                          {gothe_mile?.linkToApp?.GtemAppName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    size={{ xs: 4, sm: 6, md: 6 }}
                    sx={{ textAlign: "right" }}
                  >
                    <Button
                      className="openAppBtn"
                      target="_blank"
                      href={appUrl}
                    >
                      {gothe_mile?.linkToApp?.open}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid container sx={{ alignItems: "center" }}>
                  <Grid item size={{ xs: 8, sm: 6, md: 6 }}>
                    <Grid container sx={{ alignItems: "center" }}>
                      <Grid item size={{ xs: 3, sm: 2.5, md: 1.5 }}>
                        <Box className="browserlogo">
                          <img src={browserLogo} alt={browserName} />
                        </Box>
                      </Grid>
                      <Grid item size={{ xs: 9, sm: 8.5, md: 8.5 }}>
                        <Typography className="gotoAppTitle">
                          {browserName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    size={{ xs: 4, sm: 6, md: 6 }}
                    sx={{ textAlign: "right" }}
                  >
                    <Button
                      className="openAppBtn continueBtn"
                      onClick={stayInBrowser}
                    >
                      {gothe_mile?.linkToApp?.continue}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Footer;
